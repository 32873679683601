
<template>
  <a
    :href="to"
    class="px-3 py-2 font-medium text-sm rounded-md flex"
    :class="{
      'bg-indigo-100 text-indigo-700': selected,
      'text-gray-500 hover:text-gray-700': !selected,
    }"
  >
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'ac-tab-pill',

  props: {
    to: String,
    selected: Boolean,
  },

  provide() {
    return {
      tab: {
        selected: this.selected,
      },
    }
  },
}
</script>
