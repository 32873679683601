
<template>
  <div
    class="mb-6"
    :class="{
      'w-200': !wide,
    }"
  >
    <legend v-if="legend" class="mb-2 block text-sm font-medium text-gray-700">{{legend}}</legend>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ac-fieldset',

  props: {
    wide: Boolean,
    legend: String,
  },
}
</script>
