
<template>
  <div class="h-screen flex overflow-hidden bg-logo">
    <div class="fixed flex z-40 md:hidden top-0 bottom-0" @close="sidebarOpen = false" :open="sidebarOpen" :class="{'inset-0': sidebarOpen}">
      <Transition enter-active-class="transition-opacity ease-linear duration-300" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="transition-opacity ease-linear duration-300" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div class="fixed inset-0 bg-gray-600 bg-opacity-75" v-if="sidebarOpen" @click="sidebarOpen = false" />
      </Transition>
      <Transition enter-active-class="transition ease-in-out duration-300 transform" enter-from-class="-translate-x-full" enter-to-class="translate-x-0" leave-active-class="transition ease-in-out duration-300 transform" leave-from-class="translate-x-0" leave-to-class="-translate-x-full">
        <div class="relative flex-1 flex flex-col max-w-xs w-full bg-indigo-700" v-if="sidebarOpen">
          <Transition enter-active-class="ease-in-out duration-300" enter-from-class="opacity-0" enter-to-class="opacity-100" leave-active-class="ease-in-out duration-300" leave-from-class="opacity-100" leave-to-class="opacity-0">
            <div class="absolute top-0 right-0 -mr-12 pt-2">
              <button class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                <ac-icon light class="text-white">xmark</ac-icon>
              </button>
            </div>
          </Transition>
          <ac-sidebar></ac-sidebar>
        </div>
      </Transition>
      <div class="flex-shrink-0 w-14" v-show="sidebarOpen"></div>
    </div>

    <div class="hidden bg-indigo-700 md:flex md:flex-shrink-0">
      <div class="flex flex-col w-64">
        <ac-sidebar></ac-sidebar>
      </div>
    </div>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
        <button class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <ac-icon solid>bars</ac-icon>
        </button>
      </div>
      <main class="flex-1 relative z-0 overflow-y-scroll focus:outline-none">
        <div class="max-w-5xl pb-32 mx-auto mt-8 space-y-8">
          <router-view></router-view>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ac-app-layout',

  data() {
    return {
      sidebarOpen: false,
    }
  },
}
</script>
