
<template>
  <ac-demo
    title="Badges"
    description="Pequeñas anotaciones con colores de fondo"
    components="ac-badge"
  >
    <div class="space-x-4">
      <ac-badge-green>Verde</ac-badge-green>
      <ac-badge-red>Roja</ac-badge-red>
      <ac-badge-gray>Gris</ac-badge-gray>
      <ac-badge-yellow>Amarilla</ac-badge-yellow>
      <ac-badge-purple>Púrpura</ac-badge-purple>
      <ac-badge-indigo>Índigo</ac-badge-indigo>
      <ac-badge-pink>Rosa</ac-badge-pink>
      <ac-badge-blue>Azul</ac-badge-blue>
    </div>
  </ac-demo>
</template>

<script>
export default {
  name: 'views-badge',
}
</script>
