
<template>
  <button class="w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded-md" @click="toggleMenu">
    <slot name="trigger" :open="open"></slot>
  </button>
  <template v-if="open">
    <slot name="content" :open="open"></slot>
  </template>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: {
    defaultOpen: Boolean,
  },
  emits: ['open', 'close'],

  setup(props, { emit }) {
    let open = ref(props.defaultOpen)

    function toggleMenu() {
      if (open.value) {
        emit('close')
      } else {
        emit('open')
      }
      open.value = !open.value
    }

    return {
      open,
      toggleMenu,
    }
  },
})
</script>
