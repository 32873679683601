
<template>
  <ac-demo
    title="Selectores básicos"
    description="Selectores básicos para pocas opciones."
    components="ac-select"
  >
    <ac-form @submit="$event.waitUntil(submit())">
      <ac-fieldset>
        <ac-select name="required" label="Required" rules="required" v-model="input.required" :options="options"></ac-select>
      </ac-fieldset>
      <ac-fieldset>
        <ac-select name="empty" label="Empty" v-model="input.empty" :options="options"></ac-select>
      </ac-fieldset>
      <ac-fieldset>
        <ac-select name="preselected" label="Preselected" v-model="input.preselected" :options="options"></ac-select>
      </ac-fieldset>
      <ac-fieldset>
        <ac-select name="fit" label="Fit" v-model="input.empty" :options="options" fit></ac-select>
      </ac-fieldset>

      <ac-result>{{input}}</ac-result>

      <ac-submit>Enviar</ac-submit>
    </ac-form>
  </ac-demo>
</template>

<script>
export default {
  name: 'views-select',

  data() {
    return {
      input: {
        required: '',
        empty: '',
        preselected: 'item0',
      },
      options: [
        { label: 'Item 0', value: 'item0' },
        { label: 'Item 1', value: 'item1' },
        { label: 'Item 2', value: 'item2' },
        { label: 'Item 3', value: 'item3' },
      ],
    }
  },

  methods: {
    submit() {
      console.log('submit', this.input)
    },
  },
}
</script>
