
<template>
  <ac-demo
    title="Input múltiple"
    description="Varios inputs funcionando en tándem para introducir un array de elementos."
    components="ac-multiple-input"
  >
    <ac-form @submit="$event.waitUntil(submit())">
      <ac-fieldset>
        <ac-multiple-input name="phones" label="Teléfonos" v-model="input.phones"></ac-multiple-input>
        <ac-result>{{input.phones}}</ac-result>
      </ac-fieldset>

      <ac-fieldset>
        <ac-multiple-input name="empty" label="Lista vacía" v-model="input.empty"></ac-multiple-input>
        <ac-result>{{input.empty}}</ac-result>
      </ac-fieldset>
    </ac-form>
  </ac-demo>
</template>

<script>
export default {
  name: 'views-multiple-input',

  data() {
    return {
      input: {
        phones: ['1234', '5678'],
        empty: [],
      },
    }
  },

  methods: {
    submit() {
      console.log('submit', this.input)
    },
  },
}
</script>
