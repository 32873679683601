
<template>
  <ac-demo
    title="Copiar texto al portapapeles"
    description="Widget para copiar texto al portapapeles fácilmente con un click"
    components="ac-copy-text"
  >
    <ac-copy-text value="test/efgh">
      <code>test/efgh</code>
    </ac-copy-text>
  </ac-demo>
</template>

<script>
export default {
  name: 'views-copy-text',
}
</script>
