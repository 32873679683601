
<template>
  <ac-demo-full
    title="Tabla completa de cliente"
    description="Ejemplo completo de tabla con búsqueda y paginación introduciendo la lista de filas completa al componente antes del uso de la tabla."
  >
    <template #components>
      ac-card-table + ac-search-input +<br>
      ac-pagination
    </template>
    
    <ac-card-table empty="No hay elementos para mostrar">
      <template #title>Tarjeta con tabla</template>
      <template #description>Pequeña descripción de la tabla.</template>
      <template #actions>
        <ac-search-input placeholder="Filtrar elementos..." v-model="table.filter"></ac-search-input>
      </template>

      <template #header>
        <ac-cell-header>foo</ac-cell-header>
        <ac-cell-header>bar</ac-cell-header>
        <ac-cell-header>baz</ac-cell-header>
        <ac-cell-header>qux</ac-cell-header>
      </template>

      <template #rows>
        <ac-row v-for="row in table.rows" :key="row.foo">
          <ac-cell>{{row.foo}}</ac-cell>
          <ac-cell>{{row.bar}}</ac-cell>
          <ac-cell>{{row.baz}}</ac-cell>
          <ac-cell>{{row.qux}}</ac-cell>
        </ac-row>
      </template>

      <template #footer>
        <ac-pagination :table="table" :page-size="5"></ac-pagination>
      </template>
    </ac-card-table>
  </ac-demo-full>

  <ac-demo-full
    title="Tabla pendiente de carga"
    description="Una tabla que todavía no se ha inicializado"
  >
    <template #components>
      ac-card-table + ac-search-input +<br>
      ac-pagination
    </template>

    <ac-button-primary @click="initPending" class="mb-6">Rellenar elementos</ac-button-primary>

    <ac-card-table empty="No hay elementos para mostrar">
      <template #title>Tarjeta con tabla</template>
      <template #description>Pequeña descripción de la tabla.</template>
      <template #actions>
        <ac-search-input placeholder="Filtrar elementos..." v-model="pendingTable.filter" v-if="pendingTable"></ac-search-input>
      </template>

      <template #header>
        <ac-cell-header>foo</ac-cell-header>
        <ac-cell-header>bar</ac-cell-header>
        <ac-cell-header>baz</ac-cell-header>
        <ac-cell-header>qux</ac-cell-header>
      </template>

      <template #rows v-if="pendingTable">
        <ac-row v-for="row in pendingTable.rows" :key="row.foo">
          <ac-cell>{{row.foo}}</ac-cell>
          <ac-cell>{{row.bar}}</ac-cell>
          <ac-cell>{{row.baz}}</ac-cell>
          <ac-cell>{{row.qux}}</ac-cell>
        </ac-row>
      </template>

      <template #footer>
        <ac-pagination :table="pendingTable" :page-size="5"></ac-pagination>
      </template>
    </ac-card-table>
  </ac-demo-full>
</template>

<script>
import { range } from 'lodash-es'
import { ref } from 'vue'

import {
  useTable,
} from '/lib'


export default {
  name: 'views-full-client-side',
  
  setup() {
    let rows = []
    range(1, 24).forEach(x => {
      rows.push({
        foo: `foo ${x}`,
        bar: `bar ${x}`,
        baz: `baz ${x}`,
        qux: x % 2 ? `qux ${x}` : `qux pair ${x}`,
      })
    })

    let pendingTable = ref(null)

    return {
      table: useTable({
        rows,
        search: row => [
          row.foo,
          row.bar,
          row.baz,
          row.qux,
        ],
      }),
      pendingTable,

      initPending() {
        pendingTable.value = useTable({
          rows,
          search: row => [
            row.foo,
            row.bar,
            row.baz,
            row.qux,
          ],
        })
      },
    }
  },
}
</script>
