
<template>
  <div class="relative">
    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
      <span v-show="loading && modelValue" class="text-indigo-600">
        <ac-icon duotone spin>spinner-third</ac-icon>
      </span>
      <span v-show="!loading || !modelValue" class="text-gray-300">
        <ac-icon solid>magnifying-glass</ac-icon>
      </span>
    </div>
    <input
      type="text"
      class="form-input block py-2 pl-10 pr-3 text-sm placeholder-gray-500 bg-white border border-gray-300 w-96 shadow-sm rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      :placeholder="placeholder"
      @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)"
      :value="modelValue"
    >
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ac-search-input',

  props: {
    placeholder: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      default: '',
    },
    loading: Boolean,
  },
  emits: ['update:modelValue'],
})
</script>
