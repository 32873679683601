
<template>
  <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'ac-badge-blue',
}
</script>
