
<template>
  <tr class="odd:bg-white even:bg-gray-50">
    <slot></slot>
  </tr>
</template>

<script>
export default {
  name: 'ac-row',

  inject: ['table'],

  mounted() {
    this.table.rows++
  },

  unmounted() {
    this.table.rows--
  },
}
</script>
