

export function hideBodyScroll() {
  let scrollbarWidth = window.innerWidth - document.body.clientWidth
  document.body.style.paddingRight = `${scrollbarWidth}px`
  document.body.classList.add('force-hide-scroll')
}


export function showBodyScroll() {
  document.body.style.paddingRight = '0'
  document.body.classList.remove('force-hide-scroll')
}


export function scrollToElement(elem) {
  let parent = elem.closest('.overflow-y-scroll')
  if (!parent) {
    parent = window
  }

  let target = Math.max(elem.offsetTop - 30, 0)
  if (target > parent.scrollTop) {
    // Never scrolls down
    return
  }
  
  parent.scroll({
    top: target,
    behavior: 'smooth',
  })
}
