
<template>
  <ac-demo
    title="Submit de formularios"
    description="Botón de submit para el formulario"
    components="ac-submit"
  >
    <ac-form @submit="$event.waitUntil(submit())">
      <ac-submit fit @click="log('fit')">Botón fit</ac-submit>
      <ac-submit @click="log('normal')">Botón separado normal</ac-submit>
      <ac-submit disabled @click="log('disabled')">Botón desactivado</ac-submit>
      <ac-submit button="ac-button-white" @click="log('white')">Botón blanco</ac-submit>
    </ac-form>
  </ac-demo>
</template>

<script>
export default {
  name: 'views-submit',
  
  methods: {
    async submit() {
      await new Promise(resolve => setTimeout(resolve, 3000))
    },

    log(name) {
      console.log('button clicked', name)
    },
  },
}
</script>
