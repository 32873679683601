
<template>
  <div>
    <div class="flex">
      <label
        :for="name"
        class="block text-sm font-medium text-gray-700 flex-grow"
        :class="{'text-red-700': error}"
      >{{label}}</label>
      <div>
        <slot name="label-annotation"></slot>
      </div>
    </div>
    <div class="mt-1 relative">
      <input
        :id="name"
        :name="name"
        class="form-input shadow-sm block w-full sm:text-sm border-gray-300 rounded-md"
        :class="{
          'focus:ring-indigo-500 focus:border-indigo-500': !error,
          'border-red-300 text-red-700 placeholder-red-300 focus:border-red-300 focus:ring-red-500': error,
          [inputClass || '']: true,
          'bg-gray-100 text-gray-500 cursor-not-allowed': disabled,
        }"
        :type="type"
        v-model.trim="model"
        :disabled="disabled"
        :maxlength="maxlength"
      />
      <div v-if="error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-red-500">
        <ac-icon solid>circle-exclamation</ac-icon>
      </div>
    </div>
    <p class="mt-2 text-gray-500 text-sm" v-if="$slots.help">
      <slot name="help"></slot>
    </p>
    <p class="mt-1 text-red-700 text-sm" v-if="error">{{error}}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import { useValidations, useModel, ValidatorConstructor } from '/platform/forms'
import { maxLength, minLength, minValue, maxValue, noEndpoint, required, slug, pattern, email, url } from '/platform/validations'


export default defineComponent({
  name: 'ac-input',

  props: {
    label: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    rules: String,
    modelValue: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    disabled: Boolean,
    inputClass: String,
    maxlength: Number,
  },
  emits: ['update:modelValue'],

  setup(props, { emit }) {
    let model = useModel(toRefs(props), emit)

    let vals: ValidatorConstructor<typeof props.modelValue>[] = [
      required,
    ]
    if (props.type === 'number') {
      vals.push(minValue)
      vals.push(maxValue)
    } else {
      vals.push(minLength)
      vals.push(maxLength)
      vals.push(slug)
      vals.push(noEndpoint)
      vals.push(pattern)
      vals.push(email)
      vals.push(url)
    }
    let error = useValidations(toRefs(props), vals)
    
    return {
      model,
      error,
    }
  },
})
</script>
