
<template>
  <ac-demo
    title="Formulario"
    description="Formulario típico completo para editar o introducir datos y separación entre campos"
    components="ac-form"
  >
    <ac-form @submit="$event.waitUntil(submit())">
      <ac-fieldset>Campo 1</ac-fieldset>
      <ac-fieldset>Campo 2</ac-fieldset>
    </ac-form>
  </ac-demo>
</template>

<script>
export default {
  name: 'views-structure',

  methods: {
    submit() {
      console.log('submit', this.input)
    },
  },
}
</script>
