
<template>
  <ac-demo-full
    title="Tarjeta con tabla dentro"
    description="Tarjeta para insertar títulos o acciones sobre una subtabla"
    components="ac-card-table"
  >
    <ac-card-table empty="No hay elementos que mostrar">
      <template #title>Tarjeta con tabla</template>
      <template #description>Descripcion de la tarjeta con tabla</template>
      <template #actions>
        <ac-button-primary>Añadir</ac-button-primary>
      </template>

      <template #header>
        <ac-cell-header right>Código</ac-cell-header>
        <ac-cell-header>Nombre</ac-cell-header>
        <ac-cell-header></ac-cell-header>
      </template>

      <template #rows>
        <ac-row>
          <ac-cell right bold>test/abcd</ac-cell>
          <ac-cell class="w-full">Foo nombre</ac-cell>
          <ac-cell>
            <a href="#" class="text-indigo-600 hover:text-indigo-900">Editar</a>
          </ac-cell>
        </ac-row>
        <ac-row>
          <ac-cell right bold>test/efgh</ac-cell>
          <ac-cell class="w-full">Nombre de prueba</ac-cell>
          <ac-cell>
            <a href="#" class="text-indigo-600 hover:text-indigo-900">Editar</a>
          </ac-cell>
        </ac-row>
      </template>
    </ac-card-table>
  </ac-demo-full>

  <ac-demo-full
    title="Tarjeta con filtro intermedio"
    description="Cuando el slot de actions necesita espacio para botones podemos utilizar esta variante de filtro."
    components="ac-card-table + ac-search-input"
  >
    <ac-card-table empty="No hay elementos que mostrar">
      <template #title>Tarjeta con tabla</template>
      <template #description>Descripcion de la tarjeta con tabla</template>
      <template #actions>
        <ac-button-primary>Añadir</ac-button-primary>
      </template>
      <template #band>
        <div class="px-6 py-3">
          <ac-search-input placeholder="Filtrar elementos..."></ac-search-input>
        </div>
      </template>

      <template #header>
        <ac-cell-header right>Código</ac-cell-header>
        <ac-cell-header>Nombre</ac-cell-header>
        <ac-cell-header></ac-cell-header>
      </template>

      <template #rows>
        <ac-row>
          <ac-cell right bold>test/abcd</ac-cell>
          <ac-cell class="w-full">Foo nombre</ac-cell>
          <ac-cell>
            <a href="#" class="text-indigo-600 hover:text-indigo-900">Editar</a>
          </ac-cell>
        </ac-row>
        <ac-row>
          <ac-cell right bold>test/efgh</ac-cell>
          <ac-cell class="w-full">Nombre de prueba</ac-cell>
          <ac-cell>
            <a href="#" class="text-indigo-600 hover:text-indigo-900">Editar</a>
          </ac-cell>
        </ac-row>
      </template>
    </ac-card-table>
  </ac-demo-full>
</template>

<script>
export default {
  name: 'views-table',
}
</script>
