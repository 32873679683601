
<template>
  <ac-demo
    title="Selector de fechas"
    description="Input con selector de fechas"
    components="ac-datepicker"
  >
    <ac-form @submit="$event.waitUntil(submit())">
      <ac-fieldset>
        <ac-datepicker name="date" label="Fecha" rules="required" v-model="input.date"></ac-datepicker>
      </ac-fieldset>

      <ac-fieldset>
        <ac-datepicker name="min" label="Fecha con minimo" v-model="input.min" :min="new Date()"></ac-datepicker>
      </ac-fieldset>

      <ac-fieldset>
        <ac-datepicker name="disabled" label="Fecha deshabilitada" v-model="input.disabled" disabled></ac-datepicker>
      </ac-fieldset>
    </ac-form>
  </ac-demo>
</template>

<script>
export default {
  name: 'views-datepicker',

  data() {
    return {
      input: {
        date: null,
        min: new Date(),
        disabled: new Date(),
      },
    }
  },

  methods: {
    submit() {
      console.log('submit', this.input)
    },
  },
}
</script>
