
<template>
  <ac-demo
    title="Input de búsqueda"
    description="Registra la búsqueda conforme escribe y aplica estilos especiales"
    components="ac-search-input"
  >
    <ac-search-input placeholder="Buscar..." v-model="input.search"></ac-search-input>
  </ac-demo>

  <ac-demo
    title="Input de búsqueda cargando"
    description="Modo que se puede activar durante la búsqueda para indicar envío de datos"
    components="ac-search-input"
  >
    <ac-search-input placeholder="Buscar..." v-model="input.search" loading></ac-search-input>
  </ac-demo>
</template>

<script>
export default {
  name: 'views-search',

  data() {
    return {
      input: {
        search: 'test',
      },
    }
  },

  methods: {
    submit() {
      console.log('submit', this.input)
    },
  },
}
</script>
