
<template>
  <ac-demo
    title="Botones"
    description="Botones de todo tipo, tamaños y colores"
    components="ac-button-*"
  >
    <div class="space-y-4">
      <div class="space-x-4 flex">
        <ac-button-primary>Botón primario</ac-button-primary>
        <ac-button-primary disabled>Botón disabled</ac-button-primary>
      </div>
      <div class="space-x-4 flex">
        <ac-button-white>Botón blanco</ac-button-white>
        <ac-button-white disabled>Botón disabled</ac-button-white>
      </div>
      <div class="space-x-4 flex">
        <ac-button-danger>Botón rojo</ac-button-danger>
        <ac-button-danger disabled>Botón disabled</ac-button-danger>
      </div>
      <div class="space-x-4 flex">
        <ac-button-success>Success</ac-button-success>
        <ac-button-success disabled>Botón disabled</ac-button-success>
      </div>
      <div class="space-x-4 flex">
        <ac-button-hover-danger>Rojo hover</ac-button-hover-danger>
        <ac-button-hover-danger disabled>Botón disabled</ac-button-hover-danger>
      </div>
    </div>
  </ac-demo>
  <ac-demo
    title="Botones con link"
    description="Links que visualmente parecen botones por razones de interfaz."
    components="ac-button-link-*"
  >
    <div class="space-y-4">
      <div class="space-x-4 flex">
        <ac-button-link-primary to="/buttons/async">Botón primario</ac-button-link-primary>
      </div>
    </div>
  </ac-demo>
</template>

<script>
export default {
  name: 'views-simple',
}
</script>
