
<template>
  <ac-demo
    title="Botones radio básicos"
    description="Botones radio con un diseño clásico."
    components="ac-radio-group"
  >
    <ac-form @submit="$event.waitUntil(submit())">
      <ac-fieldset>
        <ac-radio-group label="Grupo de radios" name="group" v-model="input.group" :options="options"></ac-radio-group>
      </ac-fieldset>

      <ac-result>{{input}}</ac-result>

      <ac-submit>Enviar</ac-submit>
    </ac-form>
  </ac-demo>

  <ac-demo
    title="Botones radio de panel"
    description="Botones radio con descripción y un diseño de panel."
    components="ac-radio-panel"
  >
    <ac-form @submit="$event.waitUntil(submit())">
      <ac-fieldset>
        <ac-radio-panel label="Grupo de radios" name="group" v-model="input.group" :options="options"></ac-radio-panel>
      </ac-fieldset>

      <ac-result>{{input}}</ac-result>

      <ac-submit>Enviar</ac-submit>
    </ac-form>
  </ac-demo>
</template>

<script>
export default {
  name: 'views-radio',

  data() {
    return {
      input: {
        group: 'item1',
      },
      options: [
        { label: 'Item 0', value: 'item0', description: 'Dolor pariatur esse.' },
        { label: 'Item 1', value: 'item1', description: 'Anim minim anim nisi non irure qui exercitation nulla est pariatur ullamco deserunt sit ex non.' },
        { label: 'Item 2', value: 'item2' },
        { label: 'Item 3', value: 'item3', description: 'Dolore voluptate sit deserunt.' },
      ],
    }
  },

  methods: {
    submit() {
      console.log('submit', this.input)
    },
  },
}
</script>
