
<template>
  <ac-demo
    title="Mensaje de error"
    description="Mensaje de error global del formulario"
    components="ac-form-global-err"
  >
    <ac-form @submit="$event.waitUntil(submit())">
      <ac-form-global-err v-slot="{ message }">
        {{message}}
      </ac-form-global-err>
      <ac-submit>Submit</ac-submit>
    </ac-form>
  </ac-demo>
</template>

<script>
import { FormError } from '/platform/forms'

export default {
  name: 'views-global-err',

  methods: {
    submit() {
      return new FormError('Error en el formulario')
    },
  },
}
</script>
