
<template>
  <ac-demo
    title="Textarea"
    description="Textarea con validaciones básicas"
    components="ac-textarea"
  >
    <ac-form @submit="$event.waitUntil(submit())">
      <ac-fieldset>
        <ac-textarea name="description" label="Descripción" rules="required" v-model="input.description"></ac-textarea>
      </ac-fieldset>
    </ac-form>
  </ac-demo>
</template>

<script>
export default {
  name: 'views-textarea',
  
  data() {
    return {
      input: {
        description: 'Foo value',
      },
    }
  },

  methods: {
    submit() {
      console.log('submit', this.input)
    },
  },
}
</script>
