
import { watch, reactive, computed, ref } from 'vue'
import { prepareSearch, filterSearch } from '@altipla/normalize-search'
import { debounce } from 'lodash-es'


interface Table<T> {
  search: (row: T) => string[]
  rows: T[]
}

export function useTable<T>(table: Table<T>) {
  if (!table.rows) {
    table.rows = []
  }
  table.rows.forEach(row => prepareSearch(row, table.search(row)))
  
  let filter = ref('')
  let page = ref(0)
  let pageSize = ref(0)

  watch(filter, () => page.value = 0)

  let filteredRows = computed(() => table.rows.filter(filterSearch(filter.value)))

  return reactive({
    filter,
    rows: computed(() => filteredRows.value.slice(page.value * pageSize.value, (page.value + 1) * pageSize.value)),
    page,
    maxPages: computed(() => Math.ceil(filteredRows.value.length / pageSize.value)),
    totalSize: computed(() => filteredRows.value.length),
    loading: false,

    $configurePagination(newPageSize: number) {
      pageSize.value = newPageSize
    },

    nextPage() {
      page.value++
    },

    prevPage() {
      page.value--
    },
  })
}

interface FetchRequest {
  nextPageToken: string
  pageSize: number
  filter: string
}

interface FetchReply<T> {
  nextPageToken: string
  pageSize: number
  totalSize: number
  rows: T[]
}

interface AsyncTable<T> {
  fetch: (options: FetchRequest) => FetchReply<T>
}

export function useAsyncTable<T>(table: AsyncTable<T>) {
  let filter = ref('')
  let page = ref(0)
  let pageSize = ref(0)
  let totalSize = ref(0)
  
  let allRows: { value: T[] } = reactive({
    value: [],
  })
  
  let nextPageToken = ''
  let loading = ref(true)
  let loadingActive = 0
  let lastRequestID = 1
  async function fetchNextPage(overwrite: boolean) {
    // Start the load process and generate its identifiers.
    let id = lastRequestID++
    loadingActive = id
    loading.value = true

    let reply = await table.fetch({
      nextPageToken,
      pageSize: pageSize.value,
      filter: filter.value,
    })

    // We're not the latest request. Ignore the incomplete data.
    if (loadingActive !== id) {
      return
    }
    
    if (overwrite) {
      allRows.value = reply.rows
    } else {
      allRows.value = allRows.value.concat(reply.rows)
    }

    nextPageToken = reply.nextPageToken
    totalSize.value = reply.totalSize
    
    loading.value = false

    return reply.rows
  }

  async function reload() {
    nextPageToken = ''
    allRows.value = []
    page.value = 0
    totalSize.value = 0
    await fetchNextPage(true)
  }

  watch(filter, debounce(async () => {
    await reload()
  }, 600))

  return reactive({
    filter,
    rows: computed(() => allRows.value.slice(page.value * pageSize.value, (page.value + 1) * pageSize.value)),
    page,
    maxPages: computed(() => Math.ceil(totalSize.value / pageSize.value)),
    totalSize,
    loading,
    reload,

    async $configurePagination(newPageSize: number) {
      await fetchNextPage(true)
      pageSize.value = newPageSize
    },

    async nextPage() {
      if ((page.value + 1) * pageSize.value >= allRows.value.length) {
        await fetchNextPage(false)
      }
      page.value++
    },

    prevPage() {
      page.value--
    },

    replace(existing: T, updated: T) {
      let index = allRows.value.findIndex(row => row === existing)
      if (index === -1) {
        throw new Error(`cannot find the row in list of all rows of the table`)
      }
      allRows.value[index] = updated
    },
  })
}
