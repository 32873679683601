
<template>
  <div>
    <dl>
      <slot></slot>
    </dl>
  </div>
</template>

<script>
export default {
  name: 'ac-show',
}
</script>
