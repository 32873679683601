
<template>
  <div>
    <slot />
    <div class="flex">
      <label
        :for="name"
        class="block text-sm font-medium text-gray-700 flex-grow"
      >{{label}}</label>
      <div>
        <slot name="label-annotation"></slot>
      </div>
    </div>
    <div class="mt-3">
      <div class="space-y-3">
        <div v-for="option in allOptions" :key="option.label" class="flex items-center">
          <input
            :id="name + option.value"
            :name="name"
            type="radio"
            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
            v-model="model"
            :value="option.value"
          />
          <label :for="name + option.value" class="ml-3 block text-sm font-medium text-gray-700">
            {{option.label}}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, provide, reactive, toRefs, watch } from 'vue'
import { useModel } from '/platform/forms'
import { RADIO_OPTIONS } from '/platform/radios'


export default defineComponent({
  name: 'ac-radio-group',

  props: {
    name: {
      type: String,
      required: true,
    },
    options: Array,
    modelValue: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
    },
  },
  emits: ['update:modelValue'],

  setup(props, { emit }) {
    let model = useModel(toRefs(props), emit)

    let allOptions = reactive([])
    provide(RADIO_OPTIONS, allOptions)

    watch(toRefs(props).options, () => {
      allOptions.splice(0, allOptions.length)
      if (props.options) {
        allOptions.push(...props.options)
      }
    }, { immediate: true })

    return {
      model,
      allOptions,
    }
  },
})
</script>
