
import Home from './views/Home.vue'
import AlertsAlert from './views/alerts/Alert.vue'
import AlertsBadge from './views/alerts/Badge.vue'
import ButtonsConfirmation from './views/buttons/Confirmation.vue'
import ButtonsSimple from './views/buttons/Simple.vue'
import ButtonsAsync from './views/buttons/Async.vue'
import CardsShowPane from './views/cards/ShowPane.vue'
import CardsSimple from './views/cards/Simple.vue'
import CardsTable from './views/cards/Table.vue'
import FilesFileSelector from './views/files/FileSelector.vue'
import FilesGeneratedAvatar from './views/files/GeneratedAvatar.vue'
import FormsCheckbox from './views/forms/Checkbox.vue'
import FormsDatepicker from './views/forms/Datepicker.vue'
import FormsDualList from './views/forms/DualList.vue'
import FormsInput from './views/forms/Input.vue'
import FormsSelect from './views/forms/Select.vue'
import FormsRadio from './views/forms/Radio.vue'
import FormsSearch from './views/forms/Search.vue'
import FormsStructure from './views/forms/Structure.vue'
import FormsSubmit from './views/forms/Submit.vue'
import FormsTextarea from './views/forms/Textarea.vue'
import FormsMultipleInput from './views/forms/MultipleInput.vue'
import FormsGlobalErr from './views/forms/GlobalErr.vue'
import SideSheetsContent from './views/side-sheets/Content.vue'
import SideSheetsForm from './views/side-sheets/Form.vue'
import SideSheetsSheetEdit from './views/side-sheets/SheetEdit.vue'
import TablesSimple from './views/tables/Simple.vue'
import TablesFullClientSide from './views/tables/FullClientSide.vue'
import TablesFullServerSide from './views/tables/FullServerSide.vue'
import TabsNavigation from './views/tabs/Navigation.vue'
import UtilitiesCopyText from './views/utilities/CopyText.vue'
import UtilitiesExternalLink from './views/utilities/ExternalLink.vue'
import Header from './views/Header.vue'
import NotFound from './views/NotFound.vue'


export default [
  { path: '/', component: Home },

  { path: '/header', component: Header },

  { path: '/alerts/alert', component: AlertsAlert },
  { path: '/alerts/badge', component: AlertsBadge },

  { path: '/buttons/confirmation', component: ButtonsConfirmation },
  { path: '/buttons/simple', component: ButtonsSimple },
  { path: '/buttons/async', component: ButtonsAsync },

  { path: '/cards/show-pane', component: CardsShowPane },
  { path: '/cards/simple', component: CardsSimple },
  { path: '/cards/table', component: CardsTable },

  { path: '/files/file-selector', component: FilesFileSelector },
  { path: '/files/generated-avatar', component: FilesGeneratedAvatar },

  { path: '/forms/checkbox', component: FormsCheckbox },
  { path: '/forms/datepicker', component: FormsDatepicker },
  { path: '/forms/dual-list', component: FormsDualList },
  { path: '/forms/input', component: FormsInput },
  { path: '/forms/select', component: FormsSelect },
  { path: '/forms/radio', component: FormsRadio },
  { path: '/forms/search', component: FormsSearch },
  { path: '/forms/structure', component: FormsStructure },
  { path: '/forms/submit', component: FormsSubmit },
  { path: '/forms/textarea', component: FormsTextarea },
  { path: '/forms/multiple-input', component: FormsMultipleInput },
  { path: '/forms/global-err', component: FormsGlobalErr },

  { path: '/side-sheets/content', component: SideSheetsContent },
  { path: '/side-sheets/form', component: SideSheetsForm },
  { path: '/side-sheets/sheet-edit', component: SideSheetsSheetEdit },

  { path: '/tables/simple', component: TablesSimple },
  { path: '/tables/full-client-side', component: TablesFullClientSide },
  { path: '/tables/full-server-side', component: TablesFullServerSide },

  { path: '/tabs/navigation', component: TabsNavigation },

  { path: '/utilities/copy-text', component: UtilitiesCopyText },
  { path: '/utilities/external-link', component: UtilitiesExternalLink },
  
  { path: '/:pathMatch(.*)*', component: NotFound },
]
