
<template>
  <ac-demo
    title="Avatar"
    description="Avatar generado con las iniciales de un nombre"
    components="ac-generated-avatar"
  >
    <div>
      <div>Foo</div>
      <ac-generated-avatar class="w-10 h-10 mt-2" name="Foo"></ac-generated-avatar>
    </div>
    <div class="mt-8">
      <div>Foo bar</div>
      <ac-generated-avatar class="w-12 h-12 mt-2" name="Foo bar"></ac-generated-avatar>
    </div>
    <div class="mt-8">
      <div>Foo Bar Baz</div>
      <ac-generated-avatar class="w-20 h-20 mt-2" name="Foo Bar Baz"></ac-generated-avatar>
    </div>
    <div class="mt-8">
      <div>Prueba delay</div>
      <ac-generated-avatar class="w-20 h-20 mt-2" :name="name"></ac-generated-avatar>
    </div>
    <div class="mt-8">
      <div>Avatar Grande</div>
      <ac-generated-avatar class="w-32 h-32 mt-2" text-class="text-4xl" name="Avatar Grande"></ac-generated-avatar>
    </div>
  </ac-demo>
</template>

<script>
export default {
  name: 'views-generated-avatar',

  data() {
    return {
      name: '',
    }
  },

  async mounted() {
    await new Promise(resolve => setTimeout(resolve, 3000))
    this.name = 'Prueba delay'
  },
}
</script>
