
<template>
  <span class="inline-flex items-center justify-center rounded-full" :class="bgColor">
    <span class="font-medium leading-none text-white" :class="textClass">{{initials}}</span>
  </span>
</template>

<script>
const COLORS = [
  'bg-red-300',
  'bg-yellow-300',
  'bg-emerald-300',
  'bg-blue-300',
  'bg-indigo-300',
  'bg-purple-300',
  'bg-pink-300',
]


export default {
  name: 'ac-generated-avatar',

  props: {
    name: String,

    textClass: {
      type: String,
      default: 'text-sm',
    },
  },

  data() {
    return {
      initials: '',
      bgColor: '',
    }
  },

  watch: {
    name() {
      this.updateAvatar()
    },
  },

  mounted() {
    this.updateAvatar()
  },

  methods: {
    updateAvatar() {
      let parts = this.name.split(' ')
      
      this.initials = `${parts[0].charAt(0)}${parts[0].charAt(1)}`.toLocaleUpperCase()
      if (parts.length > 1) {
        this.initials = `${parts[0].charAt(0)}${parts[1].charAt(0)}`.toLocaleUpperCase()
      }

      let charCodes = this.initials
        .split('')
        .map(char => char.charCodeAt(0))
        .join('')

      this.bgColor = COLORS[parseInt(charCodes, 10) % COLORS.length]
    },
  },
}
</script>
