
<template>
  <button
    type="button"
    disabled
    class="inline-flex items-center px-4 py-2 bg-white text-sm font-medium border border-gray-300 leading-5 rounded-md shadow-sm text-gray-400 cursor-default"
    v-if="working"
  >
    <slot name="working">
      <ac-icon duotone spin>spinner-third</ac-icon>
      <span class="ml-3">Enviando...</span>
    </slot>
  </button>
  <button
    v-else
    :type="type"
    class="inline-flex items-center px-4 py-2 bg-white text-sm font-medium border border-gray-300 leading-5 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
    :class="{
      'text-gray-400 cursor-default': disabled,
      'text-gray-700 hover:text-gray-500 active:bg-gray-50 active:text-gray-800': !disabled,
    }"
    :disabled="disabled"
    @click="run"
  >
    <slot></slot>
  </button>
</template>

<script>
import { runAction } from '@altipla/promises'


export default {
  name: 'ac-button-async-white',

  props: {
    disabled: Boolean,
    type: {
      type: String,
      default: 'button',
    },
  },
  emits: ['action'],

  data() {
    return {
      working: false,
    }
  },

  methods: {
    async run() {
      this.working = true
      await runAction(this.$emit)
      this.working = false
    },
  },
}
</script>
