
<template>
  <div>
    <label
      :for="name"
      class="block text-sm font-medium text-gray-700"
      :class="{'text-red-700': error}"
    >{{label}}</label>
    <div class="mt-1">
      <input
        class="form-input shadow-sm block sm:text-sm border-gray-300 rounded-md"
        :class="{
          'cursor-pointer': !disabled,
          'bg-gray-100 text-gray-500 cursor-not-allowed': disabled,
        }"
        type="text"
        :id="name"
        :name="name"
        ref="input"
        :disabled="disabled"
        readonly
      >
      <div v-if="error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-red-500">
        <ac-icon solid>circle-exclamation</ac-icon>
      </div>
    </div>
    <p class="mt-2 text-gray-500 text-sm" v-if="$slots.help">
      <slot name="help"></slot>
    </p>
    <p class="mt-1 text-red-700 text-sm" v-if="error">{{error}}</p>
  </div>
</template>

<script>
import flatpickr from 'flatpickr'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import { defineComponent, toRefs } from 'vue'
import { useModel, useValidations } from '/platform/forms'
import { required } from '/platform/validations'


export default defineComponent({
  name: 'ac-datepicker',

  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    rules: String,
    modelValue: {
      type: Object,
      default: null,
    },
    disabled: Boolean,
    min: Object,
  },
  emits: ['update:modelValue'],

  data() {
    return {
      model: null,
      error: null,
    }
  },

  watch: {
    modelValue() {
      this.picker.setDate(this.modelValue)
    },

    min(newMin) {
      this._parseMin()
      if (newMin > this.picker.selectedDates[0]) {
        this.picker.setDate(this.min)
      }
      this.picker.set('minDate', this.min)
    },
  },

  mounted() {
    let model = useModel(toRefs(this.$props), this.$emit)
    let error = useValidations(toRefs(this.$props), [required])
    this.model = model
    this.error = error

    this._parseMin()
    this.picker = flatpickr(this.$refs.input, {
      dateFormat: 'd M Y',
      minDate: this.min,
      defaultDate: this.modelValue,
      locale: Spanish,
      onChange: dates => {
        this.$emit('update:modelValue', dates[0])
      },
    })
  },

  methods: {
    _parseMin() {
      if (this.min) {
        this.min.setHours(0)
        this.min.setMinutes(0)
        this.min.setSeconds(0)
        this.min.setMilliseconds(0)
      }
    },
  },
})
</script>
