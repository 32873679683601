
<template>
  <div>
    <slot />
    <div class="flex">
      <label
        :for="name"
        class="block text-sm font-medium text-gray-700 flex-grow"
      >{{label}}</label>
      <div>
        <slot name="label-annotation"></slot>
      </div>
    </div>
    <div class="mt-1">
      <div class="bg-white rounded-md -space-y-px">
        <div
          v-for="(option, index) in allOptions"
          :key="option.value"
          :value="option.value"
          :class="{
            'rounded-tl-md rounded-tr-md': index === 0,
            'rounded-bl-md rounded-br-md': index === allOptions.length - 1,
            'bg-indigo-50 border-indigo-200 z-10': option.value === modelValue,
            'border-gray-200': option.value !== modelValue,
          }"
          class="relative border p-4 flex cursor-pointer focus:outline-none"
          @click="model = option.value"
        >
          <span
            :class="{
              'bg-indigo-600 border-transparent ring-2 ring-offset-2 ring-indigo-500': option.value === modelValue,
              'bg-white border-gray-300': option.value !== modelValue,
            }"
            class="h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center"
          >
            <span class="rounded-full bg-white w-1.5 h-1.5" />
          </span>
          <div class="ml-3 flex flex-col">
            <span
              :class="{
                'text-indigo-900': option.value === modelValue,
                'text-gray-900': option.value !== modelValue,
              }"
              class="block text-sm font-medium"
            >
              {{option.label}}
            </span>
            <span
              v-if="option.description"
              as="span"
              :class="{
                'text-indigo-700': option.value === modelValue,
                'text-gray-500': option.value !== modelValue,
              }"
              class="block text-sm"
            >
              {{option.description}}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, provide, reactive, toRefs, watch } from 'vue'
import { useModel } from '/platform/forms'
import { RADIO_OPTIONS, Option } from '/platform/radios'


export default defineComponent({
  name: 'ac-radio-panel',

  props: {
    name: {
      type: String,
      required: true,
    },
    options: Array as PropType<Array<Option>>,
    modelValue: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
    },
  },
  emits: ['update:modelValue'],

  setup(props, { emit }) {
    let model = useModel(toRefs(props), emit)

    let allOptions = reactive<Option[]>([])
    provide(RADIO_OPTIONS, allOptions)

    watch(toRefs(props).options, () => {
      allOptions.splice(0, allOptions.length)
      if (props.options) {
        allOptions.push(...props.options)
      }
    }, { immediate: true })

    return {
      model,
      allOptions,
    }
  },
})
</script>
