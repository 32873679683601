
<template>
  <ac-demo-full
    title="Cabecera de página"
    description="Cabecera de todas las páginas con un solo breadcrumb."
    components="ac-header"
    fit
  >
    <ac-header>
      <template #breadcrumbs>
        <ac-breadcrumb url="/foo">Pagina</ac-breadcrumb>
      </template>
      <template #actions>
        <ac-button-primary>
          actions
        </ac-button-primary>
      </template>
    </ac-header>
  </ac-demo-full>

  <ac-demo-full
    title="Cabecera de página con breadcrumbs"
    description="Cabecera con varios breadcrumbs que sí se muestran al ser varios."
    components="ac-header + ac-breadcrumbs"
    fit
  >
    <ac-header>
      <template #breadcrumbs>
        <ac-breadcrumb url="/foo">Pagina</ac-breadcrumb>
        <ac-breadcrumb url="/foo/bar">Sección</ac-breadcrumb>
      </template>
      <template #actions>
        <ac-button-primary>
          actions
        </ac-button-primary>
      </template>
    </ac-header>
  </ac-demo-full>

  <ac-demo-full
    title="Cabecera de página con breadcrumbs opcionales"
    description="Cabecera con varios breadcrumbs pero algunos condicionales."
    components="ac-header + ac-breadcrumbs"
    fit
  >
    <ac-header>
      <template #breadcrumbs>
        <ac-breadcrumb url="/foo">Pagina</ac-breadcrumb>
        <ac-breadcrumb url="/foo/bar" v-if="false">Sección opcional</ac-breadcrumb>
        <ac-breadcrumb url="/foo/bar/baz">Sección</ac-breadcrumb>
      </template>
      <template #actions>
        <ac-button-primary>
          actions
        </ac-button-primary>
      </template>
    </ac-header>
  </ac-demo-full>
</template>

<script>
export default {
  name: 'views-header',
}
</script>
