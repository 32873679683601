
<template>
  <div>
    <div class="flex">
      <label
        :for="name"
        class="block text-sm font-medium text-gray-700 flex-grow"
        :class="{'text-red-700': error}"
      >{{label}}</label>
      <div>
        <slot name="label-annotation"></slot>
      </div>
    </div>
    <div class="mt-1 relative">
      <select
        :id="name"
        :name="name"
        class="form-select mt-1 block pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none sm:text-sm rounded-md"
        :class="{
          'w-full': !fit,
          'focus:ring-indigo-500 focus:border-indigo-500': !error,
          'border-red-300 text-red-700 focus:border-red-300 focus:ring-red-500': error,
        }"
        v-model.trim="model"
      >
        <option class="hidden"></option>
        <option v-for="option in options" :value="option.value" :key="option.value">{{option.label}}</option>
      </select>
      <div v-if="error" class="absolute inset-y-0 right-0 pr-9 flex items-center pointer-events-none text-red-500">
        <ac-icon solid>circle-exclamation</ac-icon>
      </div>
    </div>
    <p class="mt-2 text-gray-500 text-sm" v-if="$slots.help">
      <slot name="help"></slot>
    </p>
    <p class="mt-1 text-red-700 text-sm" v-if="error">{{error}}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, toRefs } from 'vue'
import { useModel, useValidations } from '/platform/forms'
import { required } from '/platform/validations'

interface Option {
  value: string,
  label: string,
}

export default defineComponent({
  name: 'ac-select',

  props: {
    label: {
      type: String,
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array as PropType<Option[]>,
      required: true,
    },
    rules: String,
    modelValue: {
      type: [String, Number],
      default: '',
    },
    fit: Boolean,
  },
  emits: ['update:modelValue'],

  setup(props, { emit }) {
    let model = useModel(toRefs(props), emit)
    let error = useValidations(toRefs(props), [
      required,
    ])

    return {
      model,
      error,
    }
  },
})
</script>
