
<template>
  <ac-icon spin duotone>spinner-third</ac-icon>
</template>

<script>
export default {
  name: 'ac-throbber',
}
</script>
