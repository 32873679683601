
<template>
  <div class="flex items-center">
    <slot></slot>
    <button
      class="text-gray-300 hover:text-gray-500 ml-2 focus:outline-none"
      title="Copiar al portapapeles"
      @click="copy"
      v-show="!success"
    >
      <ac-icon light fixed-width class="text-xl mx-2">copy</ac-icon>
    </button>
    <button
      class="text-emerald-400 ml-2 focus:outline-none"
      title="¡Copiado!"
      v-show="success"
      @mouseleave="close"
    >
      <ac-icon solid fixed-width class="text-xl mx-2">check</ac-icon>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ac-copy-text',

  props: {
    value: String,
  },

  data() {
    return {
      success: false,
    }
  },

  methods: {
    copy() {
      navigator.clipboard.writeText(this.value)
      this.success = true
    },

    close() {
      if (this.success && !this.timeout) {
        this.timeout = setTimeout(() => {
          this.success = false
          this.timeout = null
        }, 2000)
      }
    },
  },
}
</script>
