
<template>
  <div>
    <label
      :for="name"
      class="block text-sm font-medium text-gray-700"
      :class="{'text-red-700': error}"
    >{{label}}</label>
    <div class="mt-1 relative">
      <textarea
        :id="name"
        :name="name"
        :rows="rows"
        class="form-textarea shadow-sm block sm:text-sm border-gray-300 rounded-md resize-none w-full h-full"
        :class="{
          'focus:ring-indigo-500 focus:border-indigo-500': !error,
          'border-red-300 text-red-700 placeholder-red-300 focus:border-red-300 focus:ring-red-500': error,
          'bg-gray-100 text-gray-500 cursor-not-allowed': disabled,
        }"
        v-model.trim="model"
        :disabled="disabled"
      >
      </textarea>
      <div v-if="error" class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none text-red-500">
        <ac-icon solid>circle-exclamation</ac-icon>
      </div>
    </div>
    <p class="mt-2 text-gray-500 text-sm" v-if="$slots.help">
      <slot name="help"></slot>
    </p>
    <p class="mt-1 text-red-700 text-sm" v-if="error">{{error}}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import { useModel, useValidations } from '/platform/forms'
import { required, minLength, maxLength, noEndpoint, pattern } from '/platform/validations'


export default defineComponent({
  name: 'ac-textarea',

  props: {
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    rules: String,
    modelValue: {
      type: String,
      default: '',
    },
    rows: {
      type: Number,
      default: 5,
    },
    disabled: Boolean,
  },
  emits: ['update:modelValue'],

  setup(props, { emit }) {
    let model = useModel(toRefs(props), emit)
    let error = useValidations(toRefs(props), [
      required,
      minLength,
      maxLength,
      pattern,
      noEndpoint,
    ])

    return {
      model,
      error,
    }
  },
})
</script>
