
<template>
  <ac-demo-full
    title="Tabla de datos"
    description="Tabla directamente insertada en el código de la página"
    components="ac-table + ac-cell-header"
  >
    <ac-table empty="No hay elementos para mostrar">
      <template #header>
        <ac-cell-header right>Código</ac-cell-header>
        <ac-cell-header class="w-full">Nombre</ac-cell-header>
        <ac-cell-header></ac-cell-header>
      </template>
      <template #rows></template>
    </ac-table>
  </ac-demo-full>

  <ac-demo-full
    title="Tabla de datos rellena"
    description="Tabla con filas de ejemplo"
    components="ac-table + ac-cell-header + ac-row + ac-cell"
  >
    <ac-table empty="No hay elementos para mostrar">
      <template #header>
        <ac-cell-header right fit="right">Código</ac-cell-header>
        <ac-cell-header>Nombre</ac-cell-header>
        <ac-cell-header></ac-cell-header>
      </template>

      <template #rows>
        <ac-row>
          <ac-cell right bold fit="right">test/abcd</ac-cell>
          <ac-cell class="w-full">Foo nombre</ac-cell>
          <ac-cell>
            <a href="#" class="text-indigo-600 hover:text-indigo-900">Editar</a>
          </ac-cell>
        </ac-row>
        <ac-row>
          <ac-cell right bold fit="right">test/efgh</ac-cell>
          <ac-cell class="w-full">Nombre de prueba</ac-cell>
          <ac-cell>
            <a href="#" class="text-indigo-600 hover:text-indigo-900">Editar</a>
          </ac-cell>
        </ac-row>
      </template>
    </ac-table>
  </ac-demo-full>
</template>

<script>
export default {
  name: 'views-simple',
}
</script>
