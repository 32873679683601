
<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <slot name="header"></slot>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-100">
              <slot name="rows"></slot>
              <tr v-if="!table.rows">
                <td :colspan="table.cols" class="bg-white text-center text-xl italic text-gray-400 py-20">
                  {{empty}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ac-table',

  props: {
    empty: String,
  },

  data() {
    return {
      table: {
        cols: 0,
        rows: 0,
      },
    }
  },

  provide() {
    return {
      table: this.table,
    }
  },
}
</script>
