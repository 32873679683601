
<template>
  <div class="relative z-50 inline-block">
    <ac-button-hover-danger :disabled="disabled" @click="toggleOpen" v-show="!running">
      <slot></slot>&#8203;
    </ac-button-hover-danger>
    <ac-button-hover-danger disabled v-show="running">
      <ac-icon
        solid
        spin
        :class="{
          'mr-2': !iconOnly,
          'text-xl -mx-1': iconOnly,
        }"
      >
        spinner
      </ac-icon>
      {{iconOnly ? '' : sending}}
    </ac-button-hover-danger>

    <Teleport to="body">
      <div
        v-if="open"
        class="absolute z-50 mt-2 bg-white border rounded shadow-lg min-w-80"
        :class="{'right-0 box-arrow-right': !left, 'box-arrow-left': left}"
        :style="pos"
      >
        <div class="px-4 py-5 sm:p-6">
          <p class="text-xl font-medium text-center">¿Está seguro de que desea<br>continuar con la operación?</p>
          <div class="mt-10 whitespace-nowrap">
            <ac-button-danger @click="send" class="mr-8">
              <slot name="confirmation">
                <slot></slot>
              </slot>
            </ac-button-danger>
            <ac-button-white @click="open = false">
              <ac-icon solid class="mr-2">xmark</ac-icon>
              Cancelar
            </ac-button-white>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>
import { runAction } from '@altipla/promises'

import ButtonDanger from '/components/buttons/ButtonDanger.vue'
import ButtonHoverDanger from '/components/buttons/ButtonHoverDanger.vue'
import ButtonWhite from '/components/buttons/ButtonWhite.vue'


export default {
  name: 'ac-button-confirm-danger',

  components: {
    [ButtonDanger.name]: ButtonDanger,
    [ButtonHoverDanger.name]: ButtonHoverDanger,
    [ButtonWhite.name]: ButtonWhite,
  },

  computed: {
    iconOnly() {
      return !!this.$slots.default && this.$slots.default().length === 1 && this.$slots.default()[0].type.name === 'ac-icon'
    },
  },

  props: {
    disabled: Boolean,
    left: Boolean,
    sending: {
      type: String,
      default: 'Enviando...',
    },
  },
  emits: ['confirm'],

  data() {
    return {
      open: false,
      running: false,
      pos: {},
    }
  },

  methods: {
    async send() {
      this.open = false
      this.running = true

      try {
        await runAction(this.$emit, 'confirm', true)
        this.running = false
      } catch (err) {
        this.running = false
        return Promise.reject(err)
      }
    },

    toggleOpen() {
      if (!this.open) {
        let { bottom, left, right } = this.$el.getBoundingClientRect()
        if (this.left) {
          this.pos = {
            left: `${left}px`,
            top: `${bottom + window.scrollY}px`,
          }
        } else {
          this.pos = {
            right: `${window.innerWidth - right}px`,
            top: `${bottom + window.scrollY}px`,
          }
        }
      }

      this.open = !this.open
    },
  },
}
</script>
