
<template>
  <ac-demo
    title="SideSheet"
    description="Hoja flotante lateral para información avanzada o formularios"
    components="ac-side-sheet"
  >
    <div class="space-x-4">
      <ac-button-primary @click="sheets.simple = true">
        Abrir SideSheet
      </ac-button-primary>
      <ac-button-primary @click="sheets.simpleLoading = true">
        SideSheet delayed loading
      </ac-button-primary>
      <ac-button-primary @click="sheets.simpleIf = true">
        SideSheet + v-if
      </ac-button-primary>
    </div>
    <ac-side-sheet v-model="sheets.simple">
      <template #title>Titulo del sheet</template>
      <template #subtitle>Subtítulo del sheet</template>
      <template #content>Contenido</template>
    </ac-side-sheet>
    <ac-side-sheet v-model="sheets.simpleLoading" @load="$event.waitUntil(delay())">
      <template #title>Titulo del sheet</template>
      <template #subtitle>Subtítulo del sheet</template>
      <template #content>Contenido</template>
    </ac-side-sheet>
    <ac-side-sheet v-model="sheets.simpleIf" @load="$event.waitUntil(delay())" v-if="sheets.simpleIf">
      <template #title>Titulo del sheet</template>
      <template #subtitle>Subtítulo del sheet</template>
      <template #content>Contenido</template>
    </ac-side-sheet>
  </ac-demo>

  <ac-demo
    title="Espaciado"
    description="Espaciado para desatar el scroll y probar la ocultación"
  >
    <div class="h-256 bg-blue-200"></div>
  </ac-demo>
</template>

<script>
export default {
  name: 'views-content',

  data() {
    return {
      sheets: {
        simple: false,
        simpleLoading: false,
        simpleIf: false,
      },
    }
  },

  methods: {
    async delay() {
      await new Promise(resolve => setTimeout(resolve, 3000))
    },
  },
}
</script>
