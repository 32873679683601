
<template>
  <form noautocomplete @submit.prevent="submit">
    <slot></slot>
  </form>
</template>

<script lang="ts">
import { runAction } from '@altipla/promises'
import { defineComponent } from 'vue'
import { useDeclareForm, FormError } from '/platform/forms'


export default defineComponent({
  name: 'ac-form',

  emits: ['submit'],

  setup(props, { emit }) {
    let form = useDeclareForm()

    function resetState() {
      form.globalError = undefined
      form.submitted = false
      form.sending = false
    }

    return {
      resetState,

      async submit() {
        form.globalError = undefined

        form.submitted = true
        if (!form.hasErrors) {
          form.sending = true
          let reply = await runAction(emit, 'submit')
          if (reply instanceof FormError) {
            form.globalError = reply.message
            form.sending = false
            return
          }
          resetState()
        }
      },
    }
  },
})
</script>
