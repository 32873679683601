
import { computed, ComputedRef, inject, onMounted, onUnmounted, provide, reactive, ref, Ref, watch } from 'vue'

let FORM = Symbol('form')

export interface Form {
  submitted: boolean
  sending: boolean
  errors: ComputedRef<string>[]
  hasErrors: boolean
  globalError?: string
}

export function useDeclareForm(): Form {
  let form: Form = reactive({
    submitted: false,
    sending: false,
    errors: [],
    hasErrors: computed(() => !!form.errors.find(err => !!err.value)),
  })
  provide(FORM, form)

  return form
}

export function useForm(): Form {
  let form = inject<Form>(FORM)
  if (!form) {
    throw new Error('component should be nested inside a <ac-form> component')
  }

  return form
}

interface ModelProps<T> {
  modelValue: Ref<T>
}

interface EmitFn<T> {
  (name: 'update:modelValue', arg: T): void
}

export function useModel<T>(props: ModelProps<T>, emit: EmitFn<T>) {
  let model: Ref<T | undefined> = ref()

  watch(props.modelValue, () => {
    model.value = props.modelValue.value
  }, { immediate: true })

  watch(model, () => {
    emit('update:modelValue', model.value!)
  })

  return model
}

interface ValidationsProps<T> {
  modelValue: Ref<T>
  rules: Ref<string | undefined>
}

export interface Validator<T> {
  name: string
  validator: (value: T) => boolean
  message: (value: T) => string
}

export interface ValidatorConstructor<T> {
  (arg: string): Validator<T>
}

export function useValidations<T>(props: ValidationsProps<T>, validations: ValidatorConstructor<T>[]): Ref<string> {
  let form = useForm()

  let active = computed(() => {
    if (!props.rules.value) {
      return []
    }

    let vals = props.rules.value.split('|')
    return vals.map(rule => {
      let [name, arg] = rule.split(':')
      let validator = validations.find(v => v.name === name)
      if (!validator) {
        throw new Error(`unknown validation rule: ${name}`)
      }
  
      return validator(arg)
    })
  })

  let error = computed(() => {
    for (let v of active.value) {
      if (!v.validator(props.modelValue.value)) {
        return v.message(props.modelValue.value)
      }
    }
    return ''
  })

  onMounted(() => {
    form.errors.push(error)
  })

  onUnmounted(() => {
    form.errors.splice(form.errors.indexOf(error), 1)
  })

  return computed(() => {
    if (!form.submitted) {
      return ''
    }
    return error.value
  })
}

export class FormError extends Error {
  constructor(msg: string) {
    super(msg)
  }
}
