
import { Icon } from '@altipla/fa6-icon'
import { App, defineComponent } from 'vue'

import './icons'
import './styles.css'


export { useTable, useAsyncTable } from '/platform/tables'
export { FormError, useForm } from '/platform/forms'

const components = import.meta.glob<ReturnType<typeof defineComponent>>('./components/**/*.vue', { eager: true })

export let UIV1 = {
  install(app: App) {
    app.component(Icon.name, Icon)
    Object.values(components)
      .map(mod => mod.default)
      .forEach(component => app.component(component.name, component))
  },
}
