
<template>
  <ac-card>
    <template #title>ui-v1</template>
    <template #description>Admin UI shared components.</template>
    <template #content>
      <img class="mx-36 ui-my-8" src="../images/logo.svg" alt="ui-v1" />
    </template>
  </ac-card>
  <ac-card>
    <template #title>Instalación</template>
    <template #content>
      <p>Ejecuta este comando para instalar la librería:</p>
      <div class="ml-10 my-8">
        <ac-copy-text value="npm i @altipla-gcloud/ui-v1">
          <code class="font-mono">npm i @altipla-gcloud/ui-v1</code>
        </ac-copy-text>
      </div>
      <div class="ml-10 my-8">
        <ac-copy-text value="yarn add @altipla-gcloud/ui-v1">
          <code class="font-mono">yarn add @altipla-gcloud/ui-v1</code>
        </ac-copy-text>
      </div>
    </template>
  </ac-card>
  <ac-card>
    <template #title>Ejemplo de uso</template>
    <template #description>Caso sencillo de cómo instalar la librería como plugin de tu app.</template>
    <template #content>
      <pre class="font-mono text-sm">
import { createApp } from 'vue'

import { UIV1 } from '@altipla-gcloud/ui-v1'
import '@altipla-gcloud/ui-v1/style.css'


let app = createApp(YourRootComponent)
app.use(UIV1)
app.mount('#app')
      </pre>
    </template>
  </ac-card>
</template>

<script>
export default {
  name: 'views-home',
}
</script>
