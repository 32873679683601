
<template>
  <ac-alert-error v-if="form.globalError">
    <template #icon><ac-icon solid>circle-xmark</ac-icon></template>
    <template #title>
      <slot :message="form.globalError">{{message}}</slot>
    </template>
  </ac-alert-error>
</template>

<script>
import { useForm } from '/platform/forms'
import AlertError from '/components/alerts/AlertError.vue'


export default {
  name: 'ac-form-global-err',

  components: {
    [AlertError.name]: AlertError,
  },

  setup() {
    let form = useForm()
    
    return {
      form,
    }
  },
}
</script>
