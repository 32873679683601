
<template>
  <ac-demo-full
    title="Tarjeta"
    description="Tarjeta para rellenar contenido dentro con fondo blanco."
    components="ac-card"
  >
    <ac-card>
      <template #title>Titulo de la tarjeta</template>
      <template #description>Descripcion de la tarjeta</template>
      <template #actions>
        <ac-button-primary>Acciones</ac-button-primary>
      </template>
      <template #content>
        Contenido de la tarjeta
      </template>
    </ac-card>
  </ac-demo-full>
</template>

<script>
export default {
  name: 'views-simple',
}
</script>
