
<template>
  <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-emerald-100 text-emerald-800">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'ac-badge-green',
}
</script>
