
<template>
  <a :href="to" target="_blank" rel="noopener noreferrer" :class="{'group': hoverIcon}">
    <slot></slot>
    <ac-icon regular class="ml-2" :class="{'invisible group-hover:visible': hoverIcon}" v-if="icon">arrow-up-right-from-square</ac-icon>
  </a>
</template>

<script>
export default {
  name: 'ac-external-link',

  props: {
    to: String,
    hoverIcon: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
