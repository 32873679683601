
<template>
  <ac-demo
    title="Uso desde fuera"
    description="Típico código inicial para utilizar el código de más abajo."
    components="(vista)"
  >
    <pre>
&lt;SheetEdit v-model="item.showEdit" :edit="item.edit" @submit="$event.waitUntil(reloadItems())">&lt;/SheetEdit>
</pre>
  </ac-demo>

  <ac-demo
    title="SheetEdit"
    description="Típico código inicial para una hoja lateral completa que edita con formulario."
    components="SheetEdit / SheetCreate"
  >
    <pre>
&lt;template>
  &lt;ac-side-sheet-form
    :model-value="modelValue"
    @update:model-value="$emit('update:modelValue', $event)"
    @submit="$event.waitUntil(submit())"
    @load="$event.waitUntil(load())"
  >
    &lt;template v-slot:title>Editar formulario&lt;/template>
    &lt;template v-slot:content>
      &lt;ac-fieldset>
        &lt;ac-input name="code" label="Código" rules="required" v-model="input.code">&lt;/ac-input>
      &lt;/ac-fieldset>
    &lt;/template>
    &lt;template v-slot:submit>
      &lt;ac-submit>Guardar&lt;/ac-submit>
    &lt;/template>
  &lt;/ac-side-sheet-form>
&lt;/template>

&lt;script lang="ts">
import { cloneDeep } from 'lodash-es'

export default defineComponent({
  name: 'SheetEdit',

  props: {
    modelValue: Boolean,
    edit: Object as PropType&lt;Model>,
  },
  emits: ['submit', 'update:modelValue'],

  setup(props, { emit }) {
    let input = reactive({})

    async function load() {
      input.value = cloneDeep(this.edit)
      
      // ...otras cosas que quieras cargar
    }
  
    async function submit() {
      // ... enviar al servidor los datos

      emit('submit')
    }

    return {
      input,
      load,
      submit,
    }
  })
})
&lt;/script>
</pre>
  </ac-demo>
</template>

<script>
export default {
  name: 'views-sheet-edit',
}
</script>
