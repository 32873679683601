
<template>
  <div class="flex">
    <label
      :for="name"
      class="block text-sm font-medium text-gray-700 flex-grow"
    >{{label}}</label>
    <div>
      <slot name="label-annotation"></slot>
    </div>
  </div>
  <div class="flex mt-2 items-stretch space-x-2" v-for="(item, index) in model.items" :key="index">
    <div class="flex-grow">
      <ac-input :name="`${name}${index}`" v-model="model.items[index]" class="-mt-1"></ac-input>
    </div>
    <ac-button-hover-danger @click="remove(index)" :disabled="index === model.items.length - 1 || !item">
      <ac-icon solid>trash</ac-icon>
    </ac-button-hover-danger>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, reactive, watch } from 'vue'
import { dropRightWhile } from 'lodash-es'

import Input from '/components/forms/Input.vue'
import ButtonHoverDanger from '/components/buttons/ButtonHoverDanger.vue'


export default defineComponent({
  name: 'ac-multiple-input',

  components: {
    [Input.name]: Input,
    [ButtonHoverDanger.name]: ButtonHoverDanger,
  },

  props: {
    modelValue: {
      type: Array as PropType<string[]>,
      default() {
        return []
      },
    },
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue'],

  setup(props, { emit }) {
    let model: { items: string[] } = reactive({
      items: props.modelValue || [],
    })

    watch(props.modelValue, () => {
      model.items = props.modelValue
    })

    watch(model, () => {
      emit('update:modelValue', model.items.filter(v => !!v))
    })

    watch(model, () => {
      let cut = dropRightWhile(model.items, v => !v)
      cut.push('')
      if (cut.length !== model.items.length) {
        model.items = cut
      }
    }, { immediate: true })

    function remove(index: number) {
      model.items.splice(index, 1)
    }

    return {
      model,
      remove,
    }
  },
})
</script>
