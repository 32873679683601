
<template>
  <ac-card>
    <template #title>{{title}}</template>
    <template #description>{{description}}</template>
    <template #actions>
      <code>{{components}}</code>
    </template>
    <template #content>
      <slot></slot>
    </template>
  </ac-card>
</template>

<script>
export default {
  name: 'ac-demo',

  props: {
    title: String,
    description: String,
    components: String,
  },
}
</script>
