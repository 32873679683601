
<template>
  <ac-demo
    title="SideSheet con formulario"
    description="Hoja flotante lateral con un formulario directo embebido que se resetea al cancelar"
    components="ac-side-sheet-form"
  >
    <div class="space-x-4">
      <ac-button-primary @click="sheets.simple = true">
        Abrir SideSheet
      </ac-button-primary>
      <ac-button-primary @click="sheets.loading = true">
        SideSheet delayed loading
      </ac-button-primary>
      <ac-button-primary @click="sheets.if = true">
        SideSheet + v-if
      </ac-button-primary>
    </div>
    <ac-result>{{input}}</ac-result>
    <ac-side-sheet-form v-model="sheets.simple" @submit="$event.waitUntil(submitSheet())" @load="reset">
      <template #title>Titulo del sheet</template>
      <template #content>
        <ac-fieldset>
          <ac-input name="code" label="Código" rules="required" v-model="input.code"></ac-input>
        </ac-fieldset>
        <ac-fieldset wide>
          <ac-dual-list name="items" label="Items" :items="options" v-model="input.items"></ac-dual-list>
        </ac-fieldset>
      </template>
      <template #submit>
        <ac-submit fit>Guardar</ac-submit>
      </template>
    </ac-side-sheet-form>
    <ac-side-sheet-form v-model="sheets.loading" @submit="$event.waitUntil(submitSheet())" @load="$event.waitUntil(delay())">
      <template #title>Titulo del sheet</template>
      <template #content>
        <ac-input name="code" label="Código" rules="required" v-model="input.code"></ac-input>
      </template>
      <template #submit>
        <ac-submit fit>Guardar</ac-submit>
      </template>
    </ac-side-sheet-form>
    <ac-side-sheet-form v-model="sheets.if" @submit="$event.waitUntil(submitSheet())" @load="$event.waitUntil(delay())" v-if="sheets.if">
      <template #title>Titulo del sheet</template>
      <template #content>
        <ac-input name="code" label="Código" rules="required" v-model="input.code"></ac-input>
      </template>
      <template #submit>
        <ac-submit fit>Guardar</ac-submit>
      </template>
    </ac-side-sheet-form>
  </ac-demo>
  
  <ac-demo
    title="Espaciado"
    description="Espaciado para desatar el scroll y probar la ocultación"
  >
    <div class="h-256 bg-blue-200"></div>
  </ac-demo>
</template>

<script>
import { range, cloneDeep } from 'lodash-es'

export default {
  name: 'views-form',
  
  data() {
    return {
      sheets: {
        simple: false,
        simpleLoading: false,
        simpleIf: false,
      },
      options: [
        {
          id: 'test/foo',
          label: 'Foo label',
          sublabel: 'test/foo',
        },
        {
          id: 'test/bar',
          label: 'Bar baz',
          sublabel: 'test/bar',
        },
        {
          id: 'test/lorem',
          label: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
          sublabel: 'test/lorem',
        },
        ...range(25).map(i => {
          return {
            id: `test/${i}`,
            label: `Nombre ${i}`,
            sublabel: `test/${i}`,
          }
        }),
      ],
      input: {},
    }
  },

  methods: {
    reset() {
      this.input = cloneDeep({
        code: '',
        items: ['test/bar'],
      })
    },

    async delay() {
      await new Promise(resolve => setTimeout(resolve, 3000))
      this.reset()
    },

    async submitSheet() {
      await new Promise(resolve => setTimeout(resolve, 3000))
    },
  },
}
</script>
