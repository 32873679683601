
<template>
  <ac-demo-full
    title="Tabla completa de servidor"
    description="Ejemplo completo de tabla con búsqueda y paginación introduciendo la lista de filas completa al componente antes del uso de la tabla."
  >
    <template #components>
      ac-card-table + ac-search-input +<br>
      ac-pagination
    </template>
    
    <ac-card-table empty="No hay elementos para mostrar" :loading="table.loading">
      <template #title>Tarjeta con tabla</template>
      <template #description>Pequeña descripción de la tabla.</template>
      <template #actions>
        <ac-search-input placeholder="Filtrar elementos..." v-model="table.filter" :loading="table.loading"></ac-search-input>
      </template>

      <template #header>
        <ac-cell-header>foo</ac-cell-header>
        <ac-cell-header>bar</ac-cell-header>
        <ac-cell-header>baz</ac-cell-header>
        <ac-cell-header>qux</ac-cell-header>
      </template>

      <template #rows>
        <ac-row v-for="row in table.rows" :key="row.foo">
          <ac-cell>{{row.foo}}</ac-cell>
          <ac-cell>{{row.bar}}</ac-cell>
          <ac-cell>{{row.baz}}</ac-cell>
          <ac-cell>{{row.qux}}</ac-cell>
        </ac-row>
      </template>

      <template #footer>
        <ac-pagination :table="table" :page-size="5"></ac-pagination>
      </template>
    </ac-card-table>

    <div class="mt-4 space-x-4">
      <ac-button-primary @click="replace">Reemplaza tercer elemento</ac-button-primary>
      <ac-button-primary @click="reload">Recarga completa</ac-button-primary>
    </div>
  </ac-demo-full>
</template>

<script>
import { pick, range } from 'lodash-es'
import { filterSearch, prepareSearch } from '@altipla/normalize-search'

import {
  useAsyncTable,
} from '/lib'


export default {
  name: 'views-full-server-side',
  
  setup() {
    let serverRows = []
    range(1, 24).forEach(x => {
      serverRows.push({
        foo: `foo ${x}`,
        bar: `bar ${x}`,
        baz: `baz ${x}`,
        qux: x % 2 ? `qux ${x}` : `qux pair ${x}`,
      })
    })
    serverRows.forEach(row => prepareSearch(row, [row.foo, row.bar, row.baz, row.qux]))

    let table = useAsyncTable({
      async fetch({ nextPageToken, pageSize, filter }) {
        console.log('Simulated fetch', { nextPageToken, pageSize, filter })

        // Simulated server-side API.
        return new Promise(resolve => {
          setTimeout(() => {
            let rows = serverRows.filter(filterSearch(filter))

            if (nextPageToken >= rows.length) {
              resolve({
                myItems: [],
                totalSize: rows.length,
                nextPageToken: '',
              })
              return
            }

            let n = nextPageToken ? parseInt(nextPageToken, '10') : 0
            resolve({
              rows: rows
                .slice(n, n + 5)
                .map(row => pick(row, ['foo', 'bar', 'baz', 'qux'])),
              totalSize: rows.length,
              nextPageToken: `${n + 5}`,
            })
          }, 2000)
        })
      },
    })

    return {
      table,

      replace() {
        let now = new Date
        let updated = {
          ...table.rows[2],
          foo: now.toISOString(),
        }
        table.replace(table.rows[2], updated)
      },

      reload() {
        table.reload()
      },
    }
  },
}
</script>
