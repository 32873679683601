
<template>
  <th
    class="py-3 font-medium text-gray-500 tracking-wider whitespace-nowrap"
    :class="{
      'text-left': !right,
      'text-right': right,
      'text-xs uppercase': !conserveCasing,
      'text-sm': conserveCasing,
      'pl-6': fit === 'right',
      'pr-6': fit === 'left',
      'px-6': fit === 'none',
    }"
  >
    <slot></slot>
  </th>
</template>

<script>
export default {
  name: 'ac-cell-header',

  props: {
    right: Boolean,
    conserveCasing: Boolean,
    fit: {
      type: String,
      default: 'none',
    },
  },

  inject: ['table'],

  mounted() {
    this.table.cols++
  },

  unmounted() {
    this.table.cols--
  },
}
</script>
