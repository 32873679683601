
<template>
  <div class="mt-3 italic text-sm border-l-3 border-gray-300 pl-4 py-1 text-gray-500">
    <slot></slot>
    &nbsp;
  </div>
</template>

<script>
export default {
  name: 'ac-result',
}
</script>
