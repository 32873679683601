
<template>
  <nav class="relative z-0 rounded-lg shadow flex divide-x divide-gray-200">
    <slot></slot>
  </nav>
</template>

<script>
export default {
  name: 'ac-tabs-button',
}
</script>
