
<template>
  <ac-demo
    title="Alertas"
    description="Diferentes tipos de alertas, con y sin texto"
    components="ac-alert-*"
  >
    <div class="space-y-4">
      <ac-alert-success>
        <template #icon><ac-icon solid>check-circle</ac-icon></template>
        <template #title>Alerta de éxito</template>
      </ac-alert-success>
      <ac-alert-success>
        <template #icon><ac-icon solid class="mt-0.5 text-2xl">check-circle</ac-icon></template>
        <template #title>Alerta de éxito con texto</template>
        <template #content>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        </template>
      </ac-alert-success>
      <ac-alert-info>
        <template #icon><ac-icon solid>circle-info</ac-icon></template>
        <template #title>Alerta informativa</template>
      </ac-alert-info>
      <ac-alert-info>
        <template #icon><ac-icon solid class="mt-0.5 text-2xl">circle-info</ac-icon></template>
        <template #title>Alerta informativa con texto</template>
        <template #content>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        </template>
      </ac-alert-info>
      <ac-alert-warning>
        <template #icon><ac-icon solid>triangle-exclamation</ac-icon></template>
        <template #title>Alerta de advertencia</template>
      </ac-alert-warning>
      <ac-alert-warning>
        <template #icon><ac-icon solid class="mt-0.5 text-2xl">triangle-exclamation</ac-icon></template>
        <template #title>Alerta de advertencia con texto</template>
        <template #content>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        </template>
      </ac-alert-warning>
      <ac-alert-error>
        <template #icon><ac-icon solid>circle-xmark</ac-icon></template>
        <template #title>Alerta de error</template>
      </ac-alert-error>
      <ac-alert-error>
        <template #icon><ac-icon solid class="mt-0.5 text-2xl">circle-xmark</ac-icon></template>
        <template #title>Alerta de error con texto</template>
        <template #content>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        </template>
      </ac-alert-error>
    </div>
  </ac-demo>
</template>

<script>
export default {
  name: 'views-alert',
}
</script>
