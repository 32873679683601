
<template>
  <ac-demo
    title="Botones asíncronos"
    description="Botones de todos los tamaños y colores que se quedan esperando a que termine una acción."
    components="ac-button-async-*"
  >
    <div class="space-y-4">
      <div class="space-x-4 flex">
        <ac-button-async-primary @action="$event.waitUntil(delay())">Botón primario</ac-button-async-primary>
        <ac-button-async-primary disabled>Botón disabled</ac-button-async-primary>
      </div>
      <div class="space-x-4 flex">
        <ac-button-async-white @action="$event.waitUntil(delay())">Botón blanco</ac-button-async-white>
        <ac-button-async-white disabled>Botón disabled</ac-button-async-white>
      </div>
      <div class="space-x-4 flex">
        <ac-button-async-success @action="$event.waitUntil(delay())">Success</ac-button-async-success>
        <ac-button-async-success disabled>Botón disabled</ac-button-async-success>
      </div>
      <div class="space-x-4 flex">
        <ac-button-async-hover-danger @action="$event.waitUntil(delay())">Rojo hover</ac-button-async-hover-danger>
        <ac-button-async-hover-danger disabled>Botón disabled</ac-button-async-hover-danger>
      </div>
    </div>
  </ac-demo>
  <ac-demo
    title="Botones asíncronos con texto personalizado"
    description="Personalización de la etiqueta que se muestra al utilizar los botones."
    components="ac-button-async-*"
  >
    <div class="space-y-4">
      <div class="space-x-4 flex">
        <ac-button-async-primary @action="$event.waitUntil(delay())">
          <template #default>Contenido personalizado</template>
          <template #working>Cargando personalizado...</template>
        </ac-button-async-primary>
        <ac-button-async-primary disabled>Botón disabled</ac-button-async-primary>
      </div>
    </div>
  </ac-demo>
</template>

<script>
export default {
  name: 'views-async',

  methods: {
    async delay() {
      await new Promise(resolve => setTimeout(resolve, 3000))
    },
  },
}
</script>
