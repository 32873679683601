
<template>
  <nav class="bg-white px-4 py-3 flex items-center justify-between sm:px-6" aria-label="Pagination">
    <div class="hidden sm:block">
      <p class="text-sm text-gray-700" v-if="!configuring">
        Resultados
        <span class="font-medium">{{Math.min(table.page * pageSize + 1, table.totalSize)}}</span>
        -
        <span class="font-medium">{{Math.min((table.page + 1) * pageSize, table.totalSize)}}</span>
        de
        <span class="font-medium">{{table.totalSize}}</span>
      </p>
    </div>
    <div class="space-x-3 flex items-center">
      <ac-button-white :disabled="!table || !table.page || table.loading" @click="prevPage">
        <ac-icon solid class="mr-2">chevron-left</ac-icon>
        Anterior
      </ac-button-white>
      <ac-button-white :disabled="!table || !table.maxPages || table.page === table.maxPages - 1" @click="nextPage" v-show="!configuring && !table.loading">
        Siguiente
        <ac-icon solid class="ml-2">chevron-right</ac-icon>
      </ac-button-white>
      <ac-button-white disabled v-show="configuring || table.loading">
        Cargando...
        <ac-icon duotone spin class="ml-2">spinner-third</ac-icon>
      </ac-button-white>
    </div>
  </nav>
</template>

<script>
import ButtonWhite from '/components/buttons/ButtonWhite.vue'
import { scrollToElement } from '/platform/dom'


export default {
  name: 'ac-pagination',

  components: {
    [ButtonWhite.name]: ButtonWhite,
  },

  props: {
    table: {
      type: Object,
    },
    pageSize: {
      type: Number,
      default: 40,
    },
  },
  inject: {
    tableComponent: 'table',
  },

  data() {
    return {
      configuring: true,
    }
  },

  watch: {
    table: {
      immediate: true,
      async handler() {
        if (this.table) {
          await this.table.$configurePagination(this.pageSize)
          this.configuring = false
        }
      },
    },
  },

  methods: {
    prevPage() {
      this.table.prevPage()
      scrollToElement(this.tableComponent.root)
    },

    nextPage() {
      this.table.nextPage()
      scrollToElement(this.tableComponent.root)
    },
  },
}
</script>
