
<template>
  <div class="inline-block">
    <component :is="button" :disabled="working" v-show="working">
      <slot name="working">
        <ac-icon duotone spin>spinner-third</ac-icon>
        <span class="ml-3">Enviando...</span>
      </slot>
    </component>
    <component :is="button" v-show="!working" class="relative">
      <label :for="name" class="absolute inset-0 cursor-pointer"></label>
      <slot>Seleccione el fichero</slot>
    </component>
    <input
      type="file"
      :name="name"
      :id="name"
      @change="fileChanged"
      :accept="accept"
      ref="file"
      class="sr-only"
    >
  </div>
</template>

<script>
import { runAction } from '@altipla/promises'

export default {
  name: 'ac-file-selector',

  props: {
    name: {
      type: String,
      required: true,
    },
    accept: String,

    button: {
      type: String,
      default: 'ac-button-primary',
    },
  },
  emits: ['action'],

  data() {
    return {
      working: false,
    }
  },

  methods: {
    async fileChanged($event) {
      this.working = true
      await runAction(this.$emit, 'action', $event.target.files.length ? $event.target.files[0] : null)
      this.working = false
    },
  },
}
</script>
