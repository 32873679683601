
import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import routes from './routes'

import Showcase from './Showcase.vue'
import { UIV1 } from '/lib'

const components = import.meta.globEager('./components/**/*.vue')

let app = createApp(Showcase)
app.use(UIV1)

app.use(createRouter({
  history: createWebHistory(),
  routes,
}))
Object.values(components)
  .map(mod => mod.default)
  .forEach(component => app.component(component.name, component))
app.mount('#app')
