
<template>
  <div class="fixed inset-0 overflow-hidden z-50" v-show="show">
    <div class="absolute inset-0 overflow-hidden">
      <transition
        enter-active-class="ease-in-out duration-500"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in-out duration-500"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
        appear
      >
        <div
          v-show="modelValue"
          @click="$emit('update:modelValue', false)"
          class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        ></div>
      </transition>

      <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex">
        <transition
          enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
          enter-from-class="translate-x-full"
          enter-to-class="translate-x-0"
          leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
          leave-from-class="translate-x-0"
          leave-to-class="translate-x-full"
          @after-leave="closed"
          appear
        >
          <div class="w-screen max-w-lg" :class="modalClass" v-show="modelValue">
            <div class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
              <div class="flex-1 h-0 overflow-y-auto">
                <div class="py-6 px-4 bg-indigo-700 sm:px-6">
                  <div class="flex items-center justify-between">
                    <h2 id="slide-over-heading" class="text-lg font-medium text-white">
                      <slot name="title"></slot>
                    </h2>
                    <div class="ml-3 h-7 flex items-center">
                      <button
                        type="button"
                        class="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                        @click="$emit('update:modelValue', false)"
                      >
                        <ac-icon light size="2x" class="h-6 w-6">xmark</ac-icon>
                      </button>
                    </div>
                  </div>
                  <div class="mt-1" v-if="$slots.subtitle">
                    <p class="text-sm text-indigo-300">
                      <slot name="subtitle"></slot>
                    </p>
                  </div>
                </div>
                <div class="flex-1 flex flex-col justify-between">
                  <div class="px-4 sm:px-6 pt-6 pb-5">
                    <ac-throbber class="text-4xl text-indigo-600" v-if="loading"></ac-throbber>
                    <slot name="content" v-else></slot>
                  </div>
                </div>
              </div>
              <div class="flex-shrink-0 px-4 py-4 flex justify-between" v-if="$slots.actions && !loading">
                <slot name="actions"></slot>
              </div>
            </div>
          </div>
        </transition>
      </section>
    </div>
  </div>
</template>

<script>
import { runAction } from '@altipla/promises'

import Throbber from '/components/Throbber.vue'
import { hideBodyScroll, showBodyScroll } from '/platform/dom'


export default {
  name: 'ac-side-sheet',

  components: {
    [Throbber.name]: Throbber,
  },

  props: {
    // Indica si el modal está abierto o no.
    modelValue: Boolean,

    // Clases CSS para el modal lateral como tal.
    modalClass: String,
  },
  emits: ['update:modelValue', 'closed', 'load'],

  data() {
    if (this.modelValue) {
      hideBodyScroll()
    }

    return {
      show: this.modelValue,
      loading: false,
    }
  },

  watch: {
    modelValue: {
      handler() {
        if (this.modelValue) {
          hideBodyScroll()
          this.show = true
          this.load()
        }
      },
      immediate: true,
    },
  },

  beforeUnmount() {
    showBodyScroll()
    window.removeEventListener('keydown', this.listener)
  },

  methods: {
    closed() {
      this.show = false
      showBodyScroll()
      this.$emit('closed')
    },

    async load() {
      this.loading = true
      await runAction(this.$emit, 'load')
      this.loading = false

      this.listener = window.addEventListener('keydown', (e) => {
        if (this.show && e.key == 'Escape') {
          this.$emit('update:modelValue', false)
        }
      })
    },
  },
}
</script>
