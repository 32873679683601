
<template>
  <ac-demo-full
    title="Pestañas"
    description="Pestañas para vincular la navegación entre apartados."
    components="ac-tabs + ac-tab"
  >
    <ac-tabs>
      <ac-tab name="info" to="/tabs/navigation">
        Detalles generales
        <ac-tab-badge>53</ac-tab-badge>
      </ac-tab>
      <ac-tab name="bar" to="/tabs/navigation" selected>
        Pendientes
        <ac-tab-badge>3</ac-tab-badge>
      </ac-tab>
      <ac-tab name="baz" to="/tabs/navigation">Enviados</ac-tab>
      <ac-tab name="baz" to="/tabs/navigation">Rechazados</ac-tab>
    </ac-tabs>
  </ac-demo-full>

  <ac-demo
    title="Pestañas con tarjeta"
    description="Pestañas para vincular la navegación entre apartados dentro del fondo de una tarjeta."
    components="ac-tabs + ac-tab"
  >
    <ac-tabs>
      <ac-tab name="info" to="/tabs/navigation">
        Detalles generales
        <ac-tab-badge>53</ac-tab-badge>
      </ac-tab>
      <ac-tab name="bar" to="/tabs/navigation" selected>
        Pendientes
        <ac-tab-badge>3</ac-tab-badge>
      </ac-tab>
      <ac-tab name="baz" to="/tabs/navigation">Enviados</ac-tab>
      <ac-tab name="baz" to="/tabs/navigation">Rechazados</ac-tab>
    </ac-tabs>
  </ac-demo>

  <ac-demo-full
    title="Pestañas con pastillas"
    description="Pestañas para vincular la navegación entre apartados."
    components="ac-tabs-pill + ac-tab-pill"
  >
    <ac-tabs-pill>
      <ac-tab-pill name="info" to="/tabs/navigation">
        Detalles generales
        <ac-tab-badge>53</ac-tab-badge>
      </ac-tab-pill>
      <ac-tab-pill name="bar" to="/tabs/navigation" selected>
        Pendientes
        <ac-tab-badge>3</ac-tab-badge>
      </ac-tab-pill>
      <ac-tab-pill name="baz" to="/tabs/navigation">Enviados</ac-tab-pill>
      <ac-tab-pill name="baz" to="/tabs/navigation">Rechazados</ac-tab-pill>
    </ac-tabs-pill>
  </ac-demo-full>

  <ac-demo-full
    title="Pestañas con botones"
    description="Pestañas para vincular la navegación entre apartados."
    components="ac-tabs-button + ac-tab-button"
  >
    <ac-tabs-button>
      <ac-tab-button name="info" to="/tabs/navigation">
        Detalles generales
        <ac-tab-badge>53</ac-tab-badge>
      </ac-tab-button>
      <ac-tab-button name="bar" to="/tabs/navigation" selected>
        Pendientes
        <ac-tab-badge>3</ac-tab-badge>
      </ac-tab-button>
      <ac-tab-button name="baz" to="/tabs/navigation">Enviados</ac-tab-button>
      <ac-tab-button name="baz" to="/tabs/navigation">Rechazados</ac-tab-button>
    </ac-tabs-button>
  </ac-demo-full>
</template>

<script>
export default {
  name: 'views-navigation',
}
</script>
