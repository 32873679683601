
<template>
  <ac-form @submit="$event.waitUntil(submit())" ref="form" v-if="prepared">
    <ac-side-sheet
      :model-value="modelValue"
      @update:model-value="$emit('update:modelValue', $event)"
      @closed="$emit('closed')"
      @load="$event.waitUntil(load())"
      modal-class="max-w-5xl"
    >
      <template #title><slot name="title"></slot></template>
      <template #subtitle><slot name="subtitle"></slot></template>
      <template #content><slot name="content"></slot></template>

      <template #actions>
        <ac-button-white @click="cancel">Cancelar</ac-button-white>
        <slot name="submit"></slot>
      </template>
    </ac-side-sheet>
  </ac-form>
</template>

<script lang="ts">
import { defineComponent, ref, toRefs, watch } from 'vue'
import { runAction } from '@altipla/promises'

import ButtonWhite from '/components/buttons/ButtonWhite.vue'
import Form from '/components/forms/Form.vue'
import SideSheet from '/components/SideSheet.vue'


export default defineComponent({
  name: 'ac-side-sheet-form',

  components: {
    [ButtonWhite.name]: ButtonWhite,
    [Form.name]: Form,
    [SideSheet.name]: SideSheet,
  },

  props: {
    modelValue: Boolean,
  },
  emits: ['update:modelValue', 'submit', 'closed', 'load'],

  setup(props, { emit }) {
    let form = ref<{ resetState: () => void }>()
    let prepared = ref(false)

    watch(toRefs(props).modelValue, () => {
      if (props.modelValue && !prepared.value) {
        prepared.value = true
      }
    }, { immediate: true })

    return {
      form,
      prepared,

      cancel() {
        form.value?.resetState()
        emit('update:modelValue', false)
      },

      async submit() {
        let err = await runAction(emit, 'submit')
        if (err) {
          return err
        }
        emit('update:modelValue', false)
      },

      async load() {
        await runAction(emit, 'load')
      },
    }
  },
})
</script>
