
<template>
  <ac-demo
    title="Selector de ficheros"
    description="Botón que abre un selector de ficheros y lo emite una vez elegido."
    components="ac-file-selector"
  >
    <ac-file-selector name="example" @action="$event.waitUntil(selectFile($event.target))"></ac-file-selector>
    <ac-result v-if="file" class="mt-4">{{file.name}} {{file.lastModifiedDate.toISOString()}}</ac-result>
  </ac-demo>

  <ac-demo
    title="Personalización del botón"
    description="Personaliza el tipo de botón para utilizar otras variantes."
    components="ac-file-selector"
  >
    <ac-file-selector name="example2" @action="$event.waitUntil(selectFile2($event.target))" button="ac-button-white"></ac-file-selector>
    <ac-result v-if="file2" class="mt-4">{{file2.name}} {{file2.lastModifiedDate.toISOString()}}</ac-result>
  </ac-demo>
</template>

<script>
export default {
  name: 'views-file-selector',


  data() {
    return {
      file: null,
      file2: null,
    }
  },

  methods: {
    async selectFile(file) {
      this.file = file
      await new Promise(resolve => setTimeout(resolve, 3000))
    },

    async selectFile2(file) {
      this.file2 = file
      await new Promise(resolve => setTimeout(resolve, 3000))
    },
  },
}
</script>
