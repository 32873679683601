
<template>
  <button
    :type="type"
    class="inline-flex items-center px-4 py-2 text-white text-sm font-medium leading-5 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
    :class="{
      'bg-red-300 cursor-default': disabled,
      'bg-red-600 border border-red-600 hover:bg-red-700 active:bg-red-700': !disabled,
    }"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: 'ac-button-danger',

  props: {
    disabled: Boolean,
    type: {
      type: String,
      default: 'button',
    },
  },

  emits: ['click'],
}
</script>
