
<template>
  <ac-card fit class="overflow-hidden">
    <template #title>
      <slot name="title"></slot>
    </template>
    <template #description>
      <slot name="description"></slot>
    </template>
    <template #actions>
      <slot name="actions"></slot>
    </template>
    <template #footer v-if="$slots.footer && $slots.footer().length">
      <slot name="footer"></slot>
    </template>
    <template #band v-if="$slots.band && $slots.band().length">
      <slot name="band"></slot>
    </template>
    
    <template #content>
      <table class="min-w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <slot name="header"></slot>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-100">
          <slot name="rows"></slot>

          <tr v-if="!table.rows && !loading">
            <td :colspan="table.cols" class="bg-white text-center text-xl italic text-gray-400 py-20">
              {{empty}}
            </td>
          </tr>

          <tr v-if="!table.rows && loading">
            <td :colspan="table.cols" class="bg-white text-center text-4xl italic text-gray-400 py-20">
              <ac-icon duotone spin>spinner-third</ac-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ac-card>
</template>

<script>
import Card from '/components/cards/Card.vue'
import Table from '/components/table/Table.vue'


export default {
  name: 'ac-card-table',

  components: {
    [Card.name]: Card,
    [Table.name]: Table,
  },

  props: {
    empty: String,
    loading: Boolean,
  },

  data() {
    return {
      table: {
        cols: 0,
        rows: 0,
      },
    }
  },

  provide() {
    return {
      table: this.table,
    }
  },

  mounted() {
    this.table.root = this.$el
  },
}
</script>
