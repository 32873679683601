
<template>
  <ac-demo
    title="Lista dual"
    description="Para seleccionar y eliminar elementos de una lista dentro de un formulario"
    components="ac-dual-list"
  >
    <ac-form @submit="$event.waitUntil(submit())">
      <ac-fieldset wide>
        <ac-dual-list name="items" label="Items" :items="options" v-model="input.items"></ac-dual-list>
      </ac-fieldset>
    </ac-form>
  </ac-demo>
</template>

<script>
import { range } from 'lodash-es'

export default {
  name: 'views-dual-list',

  data() {
    return {
      options: [
        {
          id: 'test/foo',
          label: 'Foo label',
          sublabel: 'test/foo',
        },
        {
          id: 'test/bar',
          label: 'Bar baz',
          sublabel: 'test/bar',
        },
        {
          id: 'test/lorem',
          label: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
          sublabel: 'test/lorem',
        },
        ...range(25).map(i => {
          return {
            id: `test/${i}`,
            label: `Nombre ${i}`,
            sublabel: `test/${i}`,
          }
        }),
      ],
      input: {
        items: ['test/bar'],
      },
    }
  },

  methods: {
    submit() {
      console.log('submit', this.input)
    },
  },
}
</script>
