
<template>
  <ac-card fit>
    <template #title>{{title}}</template>
    <template #description>{{description}}</template>
    <template #actions>
      <div class="text-right">
        <code class="text-sm">
          <slot name="components">{{components}}</slot>
        </code>
      </div>
    </template>
    <template #content>
      <div
        class="bg-blue-gray-100 inline-block w-full"
        :class="{
          'p-12': !fit,
          'px-12': fit,
        }"
      >
        <slot></slot>
      </div>
    </template>
  </ac-card>
</template>

<script>
export default {
  name: 'ac-demo-full',

  props: {
    title: String,
    description: String,
    components: String,
    fit: Boolean,
  },
}
</script>
