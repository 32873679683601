
<template>
  <ac-demo
    title="Botones con confirmación"
    description="Botones que confirman la acción antes de realizarla."
    components="ac-button-confirm-danger"
  >
    <div class="space-y-4">
      <div class="space-x-4 flex">
        <ac-button-confirm-danger @confirm="$event.waitUntil(delay())">
          <ac-icon solid class="mr-2">trash</ac-icon>
          Confirmación dcha
        </ac-button-confirm-danger>
        <ac-button-confirm-danger left @confirm="$event.waitUntil(delay())">
          <ac-icon solid class="mr-2">trash</ac-icon>
          Confirmación izq
        </ac-button-confirm-danger>
        <ac-button-confirm-danger disabled>
          <ac-icon solid class="mr-2">trash</ac-icon>
          Botón disabled
        </ac-button-confirm-danger>
        <ac-button-confirm-danger @confirm="$event.waitUntil(delay())">
          <template #confirmation>
            <ac-icon solid class="mr-2">trash</ac-icon>
            Confirmación
          </template>

          <ac-icon solid>trash</ac-icon>
        </ac-button-confirm-danger>
      </div>
    </div>
  </ac-demo>

  <ac-demo-full
    title="Botones con confirmación pequeños"
    description="Botones con confirmación más pequeños para sitios con altura restringida como una tabla."
    components="ac-button-confirm-danger-sm"
  >
    <ac-table empty="No hay elementos para mostrar">
      <template #header>
        <ac-cell-header right fit="right">Código</ac-cell-header>
        <ac-cell-header>Nombre</ac-cell-header>
        <ac-cell-header></ac-cell-header>
      </template>

      <template #rows>
        <ac-row>
          <ac-cell right bold fit="right">test/abcd</ac-cell>
          <ac-cell class="w-full">Foo nombre</ac-cell>
          <ac-cell></ac-cell>
        </ac-row>
        <ac-row>
          <ac-cell right bold fit="right">test/efgh</ac-cell>
          <ac-cell class="w-full">Nombre de prueba</ac-cell>
          <ac-cell actions class="space-x-2">
            <ac-button-confirm-danger-sm @confirm="$event.waitUntil(delay())">
              <ac-icon solid class="mr-2">trash</ac-icon>
              Eliminar
            </ac-button-confirm-danger-sm>

            <ac-button-confirm-danger-sm @confirm="$event.waitUntil(delay())">
              <template #confirmation>
                <ac-icon solid class="mr-2">trash</ac-icon>
                Confirmación
              </template>

              <ac-icon solid>trash</ac-icon>
            </ac-button-confirm-danger-sm>
          </ac-cell>
        </ac-row>
      </template>
    </ac-table>
  </ac-demo-full>

  <ac-demo
    title="Botones con confirmación y scroll"
    description="Pruebas de que al hacer scroll no se desposicionan los popups"
    components="ac-button-confirm-danger"
  >
    <div class="h-256 bg-blue-200 mb-8"></div>
    <div class="space-y-4">
      <div class="space-x-4 flex">
        <ac-button-confirm-danger @confirm="$event.waitUntil(delay())">
          <ac-icon solid class="mr-2">trash</ac-icon>
          Confirmación dcha
        </ac-button-confirm-danger>
        <ac-button-confirm-danger left @confirm="$event.waitUntil(delay())">
          <ac-icon solid class="mr-2">trash</ac-icon>
          Confirmación izq
        </ac-button-confirm-danger>
        <ac-button-confirm-danger>
          <template #confirmation>
            <ac-icon solid class="mr-2">trash</ac-icon>
            Confirmación
          </template>

          <ac-icon solid>trash</ac-icon>
        </ac-button-confirm-danger>
      </div>
    </div>
  </ac-demo>
</template>

<script>
export default {
  name: 'views-confirmation',

  methods: {
    async delay() {
      await new Promise(resolve => setTimeout(resolve, 3000))
    },
  },
}
</script>
