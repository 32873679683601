
<template>
  <div>
    <label class="block text-sm font-medium text-gray-700">{{label}}</label>

    <div class="grid grid-cols-2 gap-6 mt-1">
      <div>
        <div class="bg-gray-100 border-3 border-gray-300 border-b-0 px-4 py-2 flex justify-between items-center">
          <div class="font-medium">Selección</div>

          <div class="cursor-pointer text-sm hover:text-red-600" @click="selectNone">
            <ac-icon solid class="mr-1">ban</ac-icon>
            Ninguno
          </div>
        </div>
        <div class="bg-gray-100 border-3 border-gray-300 border-b-0">
          <div class="relative">
            <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center text-gray-300">
              <ac-icon solid>magnifying-glass</ac-icon>
            </div>
            <input
              name="search"
              class="form-input w-full bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-none"
              placeholder="Escriba aqui para filtrar"
              v-model="filterSelected"
            >
          </div>
        </div>

        <ul class="bg-white border-3 border-gray-300 h-128 overflow-auto">
          <li class="relative flex justify-between hover:bg-gray-100 text-sm border-b border-gray-300" v-for="item in selected" :key="item.id">
            <div class="block flex-1 items-center px-3 py-2 whitespace-nowrap truncate" :title="item.label">
              {{item.label}}
              <span class="italic text-gray-400" v-if="item.sublabel">&nbsp;&mdash; {{item.sublabel}}</span>
              <span v-if="item.code">&nbsp;&mdash; <code>{{item.code}}</code></span>
            </div>

            <div class="flex items-center cursor-pointer px-3.5 hover:text-white hover:bg-red-600" @click="remove(item)">
              <ac-icon solid>trash</ac-icon>
            </div>
          </li>

          <li class="relative flex justify-center" v-if="!modelValue?.length">
            <p class="bg-white mb-0 italic text-lg text-gray-400 text-center py-10">No hay selección</p>
          </li>
        </ul>
      </div>
      <div>
        <div class="bg-gray-100 border-3 border-gray-300 border-b-0 px-4 py-2 flex justify-between items-center">
          <div class="font-medium">Disponibles</div>

          <div class="cursor-pointer text-sm hover:text-emerald-600" @click="selectAll">
            <ac-icon solid class="mr-1">check</ac-icon>
            Todos filtrados
          </div>
        </div>
        <div class="bg-gray-100 border-3 border-gray-300 border-b-0">
          <div class="relative">
            <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center text-gray-300">
              <ac-icon solid>magnifying-glass</ac-icon>
            </div>
            <input
              name="search"
              class="form-input w-full bg-white py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-none"
              placeholder="Escriba aqui para filtrar"
              v-model="filterItems"
            >
          </div>
        </div>

        <ul class="bg-white border-3 border-gray-300 h-128 overflow-auto">
          <template v-for="item in filteredItems" :key="item.id">
            <li class="relative flex justify-between hover:bg-gray-100 text-sm border-b border-gray-300">
              <div class="block flex-1 items-center px-3 py-2 whitespace-nowrap truncate" :title="item.label">
                {{item.label}}
                <span class="italic text-gray-400" v-if="item.sublabel">&nbsp;&mdash; {{item.sublabel}}</span>
                <span v-if="item.code">&nbsp;&mdash; <code>{{item.code}}</code></span>
              </div>

              <div class="flex items-center cursor-pointer px-3.5 hover:text-white hover:bg-indigo-500" @click="add(item)">
                <ac-icon solid>plus</ac-icon>
              </div>
            </li>
          </template>

          <li class="relative flex justify-center" v-if="selected.length === items.length">
            <p class="bg-white mb-0 italic text-lg text-gray-400 text-center py-10">No hay disponibles</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { prepareSearch, filterSearch } from '@altipla/normalize-search'
import { defineComponent, PropType, ref, toRefs, watch, computed } from 'vue'


interface Item {
  id: string | number
  label: string
  sublabel?: string
  code?: string
  search?: string
}


export default defineComponent({
  name: 'ac-dual-list',

  props: {
    label: String,
    modelValue: Array as PropType<(string|number)[]>,
    items: {
      type: Array as PropType<Item[]>,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue'],

  setup(props, { emit }) {
    watch(toRefs(props).items, () => {
      props.items.forEach(item => {
        prepareSearch(item, item.search || item.label)
      })
    }, { immediate: true })
    
    let filterSelected = ref('')
    let selected = computed<Item[]>(() => {
      if (!props.modelValue) {
        return []
      }
      
      let filter = filterSearch(filterSelected.value)
      
      return props.modelValue
        .map(id => props.items.find(item => item.id === id)!) // Assert because we filter later
        .filter(item => !!item)
        .filter(item => filter(item))
    })
    
    let filterItems = ref('')
    let filteredItems = computed<Item[]>(() => {
      let filter = filterSearch(filterItems.value)
      
      return props.items
        .filter(item => !props.modelValue?.includes(item.id))
        .filter(item => filter(item))
    })

    function selectAll() {
      emit('update:modelValue', props.items.map(item => item.id))
      filterItems.value = ''
    }
    function selectNone() {
      emit('update:modelValue')
      filterSelected.value = ''
    }
    function remove(item: Item) {
      emit('update:modelValue', props.modelValue?.filter(id => id !== item.id))
    }
    function add(item: Item) {
      let model = props.modelValue || []
      emit('update:modelValue', [...model, item.id])
    }
    
    return {
      filterItems,
      filterSelected,
      filteredItems,
      selected,
      selectAll,
      selectNone,
      remove,
      add,
    }
  },
})
</script>
