
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBan as faBanSolid,
  faChevronLeft as faChevronLeftSolid,
  faChevronRight as faChevronRightSolid,
  faPencil as faPencilSolid,
  faMagnifyingGlass as faMagnifyingGlassSolid,
  faCheck as faCheckSolid,
  faCheckCircle as faCheckCircleSolid,
  faTriangleExclamation as faTriangleExclamationSolid,
  faCircleExclamation as faCircleExclamationSolid,
  faCircleInfo as faCircleInfoSolid,
  faPlus as faPlusSolid,
  faCircleXmark as faCircleXMarkSolid,
  faTrash as faTrashSolid,
  faSpinner as faSpinnerSolid,
  faBars as faBarsSolid,
  faXmark as faXmarkSolid,
  faH1 as faH1Solid,
  faTable as faTableSolid,
  faNewspaper as faNewspaperSolid,
  faUpload as faUploadSolid,
  faClockRotateLeft as faClockRotateLeftSolid,
} from '@fortawesome/pro-solid-svg-icons'
import {
  faArrowUpRightFromSquare as faArrowUpRightFromSquareRegular,
  faTable as faTableRegular,
  faCreditCardBlank as faCreditCardBlankRegular,
  faFileLines as faFileLinesRegular,
  faSquarePen as faSquareRegular,
  faBarsProgress as faBarsProgressRegular,
  faSensorTriangleExclamation as faSensorTriangleExclamationRegular,
  faTriangleExclamation as faTriangleExclamationRegular,
  faFileImage as faFileImageRegular,
  faScrewdriverWrench as faScrewdriverWrenchRegular,
  faCircleInfo as faCircleInfoRegular,
  faHouse as faHouseRegular,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faCopy as faCopyLight,
  faXmark as faXmarkLight,
  faTable as faTableLight,
  faFileLines as faFileLinesLight,
  faImages as faImagesLight,
} from '@fortawesome/pro-light-svg-icons'
import {
  faCircleNotch as faCircleNotchDuotone,
  faSpinnerThird as faSpinnerThirdDuotone,
  faRectangle as faRectangleDuotone,
} from '@fortawesome/pro-duotone-svg-icons'

library.add(
  faBanSolid,
  faBarsSolid,
  faCheckCircleSolid,
  faCheckSolid,
  faChevronLeftSolid,
  faChevronRightSolid,
  faCircleNotchDuotone,
  faCopyLight,
  faCreditCardBlankRegular,
  faTriangleExclamationSolid,
  faCircleExclamationSolid,
  faFileImageRegular,
  faH1Solid,
  faNewspaperSolid,
  faPencilSolid,
  faPlusSolid,
  faMagnifyingGlassSolid,
  faSpinnerSolid,
  faSpinnerThirdDuotone,
  faTableLight,
  faTableRegular,
  faTableSolid,
  faTrashSolid,
  faImagesLight,
  faUploadSolid,
  faCircleInfoSolid,
  faCircleXMarkSolid,
  faXmarkSolid,
  faClockRotateLeftSolid,
  faArrowUpRightFromSquareRegular,
  faFileLinesRegular,
  faSquareRegular,
  faBarsProgressRegular,
  faSensorTriangleExclamationRegular,
  faTriangleExclamationRegular,
  faScrewdriverWrenchRegular,
  faCircleInfoRegular,
  faXmarkLight,
  faFileLinesLight,
  faRectangleDuotone,
  faHouseRegular,
)
