
<template>
  <label class="block text-sm font-medium text-gray-700">
    <input
      type="checkbox"
      class="form-checkbox h-4 w-4 mr-2 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
      :id="name"
      :value="value"
      v-model="model"
    >
    {{label}}
  </label>
</template>

<script lang="ts">
import { defineComponent, toRefs } from 'vue'
import { useModel } from '/platform/forms'

export default defineComponent({
  name: 'ac-checkbox',

  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [Boolean, Array],
      default: false,
    },
  },
  emits: ['update:modelValue'],

  setup(props, { emit }) {
    let model = useModel(toRefs(props), emit)
    return {
      model,
    }
  },
})
</script>
