
<template>
  <ac-demo
    title="Checkbox"
    description="Checkboxes para los formularios"
    components="ac-checkbox"
  >
    <ac-form @submit="$event.waitUntil(submit())">
      <ac-fieldset legend="v-model utilizando Boolean">
        <div>
          <ac-checkbox name="checkbox-single" v-model="input.single" label="Simple Checkbox" />
        </div>
        <ac-result>v-model: {{input.single}}</ac-result>
      </ac-fieldset>

      <ac-fieldset legend="v-model utilizando Boolean">
        <div class="grid grid-cols-6">
          <ac-checkbox name="lang-de" value="de" v-model="input.list" label="Alemán" />
          <ac-checkbox name="lang-en" value="en" v-model="input.list" label="Inglés" />
          <ac-checkbox name="lang-es" value="es" v-model="input.list" label="Castellano" />
        </div>
        <ac-result>v-model: {{input.list}}</ac-result>
      </ac-fieldset>
    </ac-form>
  </ac-demo>
</template>

<script>
export default {
  name: 'views-checkbox',

  data() {
    return {
      input: {
        single: true,
        list: ['es', 'en'],
      },
    }
  },

  methods: {
    submit() {
      console.log('submit', this.input)
    },
  },
}
</script>
