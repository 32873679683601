
<template>
  <a
    type="button"
    :href="to"
    class="inline-flex items-center px-4 py-2 text-sm font-medium border border-transparent leading-5 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 cursor-pointer"
    :class="{
      'bg-indigo-300 cursor-default': disabled,
      'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 text-white hover:text-white': !disabled,
    }"
  >
    <slot></slot>
  </a>
</template>

<script>
export default {
  name: 'ac-button-link-primary',

  props: {
    to: {
      type: String,
      default(props) {
        return props.url
      },
    },

    disabled: Boolean,

    /**
     * @deprecated Use `to` instead.
     */
    url: String,
  },
}
</script>
