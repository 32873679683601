
import { Validator } from './forms'

const EMAIL_RE = /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/
const URL_RE = /^[a-z][a-z\d.+-]*:\/*(?:[^:@]+(?::[^@]+)?@)?(?:[^\s:/?#]+|\[[a-f\d:]+\])(?::\d+)?(?:\/[^?#]*)?(?:\?[^#]*)?(?:#.*)?$/i
const SLUG_RE = /^[a-z\d][a-z\d-]*$/g

export function required<T>(): Validator<T> {
  return {
    name: 'required',
    validator: (value: T) => !!value,
    message: () => 'Este campo es un valor requerido',
  }
}

export function minLength<T extends string | number>(arg: string): Validator<T> {
  let n = parseInt(arg, 10)
  return {
    name: 'min',
    validator: (value: string | number) => `${value}`.length >= n,
    message: () => `Este campo necesita al menos ${n} caracteres`,
  }
}

export function maxLength<T extends string | number>(arg: string): Validator<T> {
  let n = parseInt(arg, 10)
  return {
    name: 'max',
    validator: value => `${value}`.length <= n,
    message: () => `Este campo puede tener como máximo ${n} caracteres`,
  }
}

export function url<T extends string | number>(): Validator<T> {
  return {
    name: 'url',
    validator: value => !!value && !!`${value}`.match(URL_RE),
    message: () => `Este campo debe contener un link válido a una página o URL de Internet`,
  }
}

export function email<T extends string | number>(): Validator<T> {
  return {
    name: 'email',
    validator: value => !!value && !!`${value}`.match(EMAIL_RE),
    message: () => `Este campo debe ser una dirección de email válida`,
  }
}

export function slug<T extends string | number>(): Validator<T> {
  return {
    name: 'slug',
    validator: value => !!value && !!`${value}`.match(SLUG_RE),
    message: () => `Este campo debe tener solo minúsculas, números o guiones`,
  }
}

export function noEndpoint<T extends string | number>(): Validator<T> {
  return {
    name: 'no_end_point',
    validator: value => value && !`${value}`.endsWith('.'),
    message: () => `Este campo no puede terminar con un punto`,
  }
}

export function pattern<T extends string | number>(arg: string): Validator<T> {
  let re = new RegExp(arg)
  return {
    name: 'pattern',
    validator: value => !!value && !!`${value}`.match(re),
    message: () => `Este campo debe cumplir con las indicaciones recomendadas en la ayuda`,
  }
}

export function minValue<T extends string | number>(arg: string): Validator<T> {
  let n = parseInt(arg, 10)
  return {
    name: 'min_value',
    validator: value => !!value && parseInt(`${value}`, 10) >= n,
    message: () => `Este campo debe ser numérico y tiene como valor mínimo ${n}`,
  }
}

export function maxValue<T extends string | number>(arg: string): Validator<T> {
  let n = parseInt(arg, 10)
  return {
    name: 'max_value',
    validator: value => !!value && parseInt(`${value}`, 10) <= n,
    message: () => `Este campo debe ser numérico y tiene como valor máximo ${n}`,
  }
}
