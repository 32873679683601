
<template>
  <ac-demo-full
    title="ShowPane"
    description="Parejas de clave valor para mostrar datos de una entidad en una tarjeta"
    components="ac-show + ac-show-pane"
  >
    <ac-card fit>
      <template #title>Titulo de la tarjeta</template>
      <template #content>
        <ac-show>
          <ac-show-pane name="Código">
            <ac-copy-text value="test/efgh">
              <code>test/efgh</code>
            </ac-copy-text>
          </ac-show-pane>
          <ac-show-pane name="Nombre de entidad">Nombre de prueba</ac-show-pane>
        </ac-show>
      </template>
    </ac-card>
  </ac-demo-full>

  <ac-demo-full
    title="ShowPane fit"
    description="Un ShowPane que ocupa menos espacio en su label para poder meter dos"
    components="ac-show + ac-show-pane[fit]"
  >
    <div class="grid grid-cols-2 gap-x-6">
      <ac-card fit>
        <template #title>Titulo de la tarjeta</template>
        <template #content>
          <ac-show>
            <ac-show-pane name="Código" fit>
              <ac-copy-text value="test/efgh">
                <code>test/efgh</code>
              </ac-copy-text>
            </ac-show-pane>
            <ac-show-pane name="Nombre Entidad" fit>Nombre de prueba</ac-show-pane>
          </ac-show>
        </template>
      </ac-card>

      <ac-card fit>
        <template #title>Titulo de la tarjeta</template>
        <template #content>
          <ac-show>
            <ac-show-pane name="Código" fit>
              <ac-copy-text value="test/efgh">
                <code>test/efgh</code>
              </ac-copy-text>
            </ac-show-pane>
            <ac-show-pane name="Nombre Entidad" fit>Nombre de prueba</ac-show-pane>
          </ac-show>
        </template>
      </ac-card>
    </div>
  </ac-demo-full>
</template>

<script>
export default {
  name: 'views-show-pane',
}
</script>
