
<template>
  <ac-demo
    title="Link externo"
    description="Link a un destino externo al panel administrativo"
    components="ac-external-link"
  >
    <ac-external-link to="https://www.google.com">
      Link externo
    </ac-external-link>
  </ac-demo>
</template>

<script>
export default {
  name: 'views-external-link',
}
</script>
