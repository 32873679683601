
<template>
  <button
    type="button"
    disabled
    class="inline-flex items-center px-4 py-2 text-sm font-medium border border-transparent leading-5 rounded-md shadow-sm bg-indigo-300 cursor-default text-white"
    v-if="working"
  >
    <slot name="working">
      <ac-icon duotone spin>spinner-third</ac-icon>
      <span class="ml-3">Enviando...</span>
    </slot>
  </button>
  <button
    v-else
    :type="type"
    class="inline-flex items-center px-4 py-2 text-sm font-medium border border-transparent leading-5 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
    :class="{
      'bg-indigo-300 cursor-default text-white': disabled,
      'bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 text-white': !disabled,
    }"
    :disabled="disabled"
    @click="run"
  >
    <slot></slot>
  </button>
</template>

<script>
import { runAction } from '@altipla/promises'


export default {
  name: 'ac-button-async-primary',

  props: {
    disabled: Boolean,
    type: {
      type: String,
      default: 'button',
    },
  },
  emits: ['action'],

  data() {
    return {
      working: false,
    }
  },

  methods: {
    async run() {
      this.working = true
      await runAction(this.$emit)
      this.working = false
    },
  },
}
</script>
