
<template>
  <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-500 text-white">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'ac-badge-gray',
}
</script>
