
<template>
  <div :class="{'mt-12': !fit}">
    <component :is="button" v-if="form.sending" disabled>
      <ac-icon duotone class="mr-2" spin>circle-notch</ac-icon>
      Enviando datos...
    </component>
    <component :is="button" type="submit" v-else :disabled="disabled || form.submitted && form.hasErrors" @click="$emit('click')">
      <slot></slot>
    </component>
  </div>
</template>

<script lang="ts">
import { useForm } from '/platform/forms'


export default {
  name: 'ac-submit',

  emits: ['click'],

  props: {
    fit: Boolean,
    disabled: Boolean,
    button: {
      type: String,
      default: 'ac-button-primary',
    },
  },

  setup() {
    let form = useForm()

    return {
      form,
    }
  },
}
</script>
