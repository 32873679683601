
<template>
  <ac-input v-bind="$attrs" :name="name" :maxlength="maxlength" v-model="model">
    <template #label-annotation>
      <span class="text-xs">{{model ? model.length : 0}}/{{maxlength}}</span>
    </template>
  </ac-input>
</template>

<script lang="ts">
import Input from '/components/forms/Input.vue'
import { defineComponent, toRefs } from 'vue'
import { useModel } from '/platform/forms'


export default defineComponent({
  name: 'ac-input-counter',

  components: {
    [Input.name]: Input,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    maxlength: {
      type: Number,
      required: true,
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  inheritAttrs: false,
  emits: ['update:modelValue'],

  setup(props, { emit }) {
    let model = useModel(toRefs(props), emit)

    return {
      model,
    }
  },
})
</script>
