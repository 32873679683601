
<template>
  <div class="bg-white shadow rounded-lg divide-y divide-gray-200 self-start">
    <div class="px-4 py-5 sm:px-6">
      <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-nowrap">
        <div class="ml-4 mt-4">
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            <slot name="title"></slot>
          </h3>
          <p class="mt-1 text-sm text-gray-500" v-if="$slots.description">
            <slot name="description"></slot>
          </p>
        </div>
        <div class="ml-4 mt-4 flex-shrink-0" v-if="$slots.actions">
          <slot name="actions"></slot>
        </div>
      </div>
    </div>

    <slot name="band"></slot>

    <div :class="{'px-4 py-5 sm:p-6': !fit}">
      <slot name="content"></slot>
    </div>

    <div :class="{'px-4 py-4 sm:px-6': !fit}" v-if="$slots.footer && $slots.footer().length">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ac-card',

  props: {
    fit: Boolean,
  },
}
</script>
