
<template>
  <ac-demo
    title="Input"
    description="Inputs con validaciones básicas"
    components="ac-input"
  >
    <ac-form @submit="$event.waitUntil(submit())">
      <ac-fieldset>
        <ac-input name="displayName" label="Nombre" rules="required" v-model="input.displayName"></ac-input>
      </ac-fieldset>
      <ac-fieldset>
        <ac-input type="password" name="password" label="Contraseña" rules="required" v-model="input.password"></ac-input>
      </ac-fieldset>
      <ac-fieldset>
        <ac-input type="number" name="number" label="Número" rules="required" v-model="input.number"></ac-input>
      </ac-fieldset>

      <ac-submit>Enviar</ac-submit>
    </ac-form>
  </ac-demo>

  <ac-demo
    title="Input con contador"
    description="Input normal con un contador de caracteres a la derecha."
    components="ac-input-counter"
  >
    <ac-form @submit="$event.waitUntil(submit())">
      <ac-fieldset>
        <ac-input-counter name="counter" label="Contador" v-model="input.counter" :maxlength="20"></ac-input-counter>
      </ac-fieldset>
      
      <ac-submit>Enviar</ac-submit>
    </ac-form>
  </ac-demo>
</template>

<script>
export default {
  name: 'views-input',

  data() {
    return {
      input: {
        displayName: 'asdfdsa',
        password: '1234',
        number: 1,
        counter: '1234567890',
      },
    }
  },

  methods: {
    submit() {
      console.log('submit', this.input)
    },
  },
}
</script>
