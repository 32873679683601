
<template>
  <a :href="url" class="text-gray-500 hover:text-gray-700 transition duration-150 ease-in-out cursor-pointer">
    <slot></slot>
  </a>
  <ac-icon solid class="mx-3 text-gray-400 text-xs last:invisible">chevron-right</ac-icon>
</template>

<script>
export default {
  name: 'ac-breadcrumb',

  props: {
    url: String,
  },
}
</script>
